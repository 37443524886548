<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")' class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('common.season') }}</label>
        <input type="text" v-model="data.season" :placeholder='$t("common.season")'
               :disabled="true"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('works.parcels.culture') }}</label>
        <b-form-select
            v-model="data.culture_uuid"
            :placeholder="$t('works.parcels.culture')"
            class="form-control"
            :options="culturelList"></b-form-select>
      </div>
      <div class="text-primary fs12" v-html="$t('works.parcels.could_not_find', { parcel_uuid: data.uuid })"></div>
    </div>

    <div class="row">
      <div class="form-group col-sm-2 col-lg-2">
        <label class="control-label"> </label>
        <agro-filter
            :singleSelect="true"
            @onChange="onDynamicFiltersChanged"/>
      </div>
    </div>

    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {buildDefaultDropdownList} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import AgroFilter from "~/components/AgroFilter/Index.vue";

export default {
  name: 'ParcelDetails',
  components: {
    Forbidden,
    AgroActionButtons,
    AgroFilter
  },
  data() {
    return {
      data: {},
      showAgroFilterDialog: false,
      agroFilterDialogData: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      currentDepartment: 'auth/currentDepartment',
      parcels: 'works-parcels/parcels',
      cultures: 'works-cultures/cultures',
      departments: 'company/departments',
    }),
    name() {
      return this.$t('works.parcels.name')
    },
    config() {
      return {
        permission: permissions.PARCELS,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/works/parcels',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this) && this.data.is_deletable;
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    culturelList() {
      return buildDefaultDropdownList(Object.assign(this.cultures).filter((item) => {
        return item.name !== this.data.name;
      }));
    },
  },
  methods: {
    ...mapActions({
        getCultures: 'works-cultures/getCultures',
        get: 'works-parcels/getParcel',
        create: 'works-parcels/createParcel',
        update: 'works-parcels/updateParcel',
        delete: 'works-parcels/deleteParcel',
        getDepartments: 'company/getDepartments',
        listDepartments: 'department/listDepartments',
        getDepartmentFilterTypes: 'agroFilter/getDepartmentFilterTypes'
    }),
    loadData() {
      return this.actions.getData(this, () => {
          this.data.parent_uuid = null;
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      this.data.department_uuid = this.currentDepartment.department_uuid;
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
    onDynamicFiltersChanged(selectedUUID) {
      this.data.new_department_uuid = selectedUUID;
    },
  },
  mounted() {
    this.getCultures({
      department_uuid: this.currentDepartment.department_uuid,
      enablePagination: 0,
      limit: 9999,
      is_variety: 0,
    });
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
  },
  validations(){
    return {
      data: {
        name: {
          required,
        },
      }
    }
  }
}
</script>

<style scoped>

</style>
