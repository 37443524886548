<template>
  <td :class="[td.class, tdAlign, rowClass ]" :width="td.with ? td.width : 'auto'">
    <div id="#render"></div>
    <div  v-if="!header.colTemplate" v-html="td"></div>
    <div v-else>
      <component :is="component" v-bind="{data: cell}" v-if="component"></component>
    </div>
  </td>
</template>

<script>
export default {
  name: 'Row',
  props: ['data', 'headers', 'align', 'rowClass', 'header', 'cell'],
  data() {
    return {
      td: this.data ? this.data : [],
      templateRender: null,
      child_component: null,
    }
  },
  computed: {
    tdAlign() {
      if(this.align) {
        switch(this.align) {
          case 'center':
            return 'text-center';
          case 'left':
            return 'text-left'
            case 'right':
              return 'text-right'
          default:
            return 'text-left';
        }
      }
    },
    component() {
      return this.header.colTemplate(this.cell) ?? null;
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.td = newVal;
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>
