import * as types from "../../mutation-types";

const mutations = {
    [types.SET_MEASURE_UNITS]: (state, payload) => {
        state.measure_units = payload;
    },
    [types.SET_MEASURE_UNIT]: (state, payload) => {
        state.measure_unit = payload;
    },
    [types.ADD_MEASURE_UNIT_ITEM]: (state, payload) => {
        state.measure_units.push(payload);
    },
    [types.UPDATE_MEASURE_UNIT_ITEM]: (state, payload) => {
        state.measure_units.splice(state.measure_units.indexOf(
            state.measure_units.find(c => c.uuid === payload.uuid)
        ), 1, payload);
    },
    [types.DELETE_MEASURE_UNIT_ITEM]: (state, payload) => {
        state.measure_units.splice(state.measure_units.indexOf(
            state.measure_units.find(c => c.uuid === payload.uuid)
        ), 1);
    },
    [types.SET_MEASURE_UNIT_FILTERS]: (state, payload) => {
        state.measure_unit_filters = payload;
    },
}

export default mutations;
