<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="grid-filter-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ $t('salary_calculator.reports.filters.title') }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg form-group">
              <label class="control-label">{{$t("salary_calculator.employee.name")}}</label>
              <v-select
                v-model="employee"
                class=""
                placeholder="-"
                autocomplete="off"
                @option:selected="onEmployeeSelected"
                taggable
                :options="employee_list"></v-select>
            </div>
            <div class="col-lg form-group">
              <label class="control-label">{{$t("salary_calculator.work.name")}}</label>
              <v-select
                v-model="work"
                class=""
                placeholder="-"
                autocomplete="off"
                @option:selected="onWorkSelected"
                taggable
                :options="work_list"></v-select>
            </div>
            <div class="col-lg form-group">
              <label class="control-label">{{$t("common.date_from")}}</label>
              <date-picker2
                v-model="work_started_at"
                :value-type="work_started_at_from_input"
                format="YYYY-MM-DD"
                type="date"
                placeholder="yyyy-mm-dd"
                :input-class="['date-picker-style']"
                @change="onWorkStartedAtChanged"
                ref="date_from"
              ></date-picker2>
            </div>
            <div class="col-lg form-group">
              <label class="control-label">{{$t("common.date_to")}}</label>
              <date-picker2
                v-model="work_ended_at"
                :value-type="work_ended_at_from_input"
                format="YYYY-MM-DD"
                type="date"
                placeholder="yyyy-mm-dd"
                :input-class="['date-picker-style']"
                @change="onWorkEndedAtChanged"
                ref="date_from"
              ></date-picker2>
            </div>
          </div>
        </div>
        <div class="modal-footerx col-lg-12">
          <div class="form-group row justify-content-end">
            <div class="col-lg-2 fc">
              <button class="modal-default-button btn btn-outline-secondary mr-5" @click="exportDailyReport()">
                <span class="ic">
                  <img src="~@/assets/images/desktop/icons/controls/pdf.svg" class="icon-svg secondary-filter"/>
                </span>
                {{$t("common.export_to_pdf")}}
              </button>
            </div>
            <div class="col-lg-2 fc">
              <button class="modal-default-button btn btn-outline-primary mr-5" @click="OK()">{{$t("common.apply")}}</button>
            </div>
            <div class="col-lg-1 fc">
              <button class="modal-default-button btn btn-outline-secondary mr-5" @click="close()">{{$t("common.close")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {buildDefaultAutocompleteList, errorMessage, infoMessage} from "~/helpers/common";
import {mapActions, mapGetters} from "vuex";
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as endpoints from '@/store/api/endpoints';

export default {
  name: 'GridFilterDialog',
  props: ['show', 'data'],
  data: function () {
    return {
      modalData: this.data,
      error: '',
      filters: {},
      work_started_at: null,
      work_ended_at: null,
      work_started_at_from_input: null,
      work_ended_at_from_input: null,
      employee: null,
      work: null,
    };
  },
  components: {
    DatePicker2,
  },
  computed: {
    ...mapGetters({
      employees: 'scEmployee/scEmployees',
      works: 'scWorkPivot/scWorkPivots',
    }),
    work_list() {
      return buildDefaultAutocompleteList(this.works);
    },
    employee_list() {
      return buildDefaultAutocompleteList(this.employees);
    },
  },
  methods: {
    ...mapActions({
      getEmployees: 'scEmployee/getSCEmployees',
      getWorks: 'scWorkPivot/getSCWorkPivots',
    }),
    close: function () {
      this.$emit('close');
    },
    OK () {
      this.$emit('onOK', this.filters ?? []);
      this.close();
    },
    onFiltersChanged(data) {
      this.filters = data;
    },
    onWorkStartedAtChanged(date) {
      this.filters.work_started_at = date;
    },
    onWorkEndedAtChanged(date) {
      this.filters.work_ended_at = date;
    },
    onEmployeeSelected(data) {
      this.filters.employee_uuid = data.value;
    },
    onWorkSelected(data) {
      this.filters.work_uuid = data.value;
    },
    exportDailyReport() {
      let vm = this;
      infoMessage(vm, vm.$t('common.download_in_progress', { ext: 'Daily report'}));
      this.$store.dispatch('scWorkPivot/downloadDailyReport', {
        type: 'pdf',
        url: endpoints.SC_DOWNLOAD_DAILY_REPORT,
        filename: 'daily-report.pdf',
        response_type: 'blob',
        filters: this.filters,
      }).
      then((resp) => {
        console.log('downloaded pdf response', resp);
      }).catch((e) => {
        console.log('check export error: ', e.response);
        if(e.response.status === 400 || e.response.status === 500) {
          if(e.response.data.errors && e.response.data.errors.error) {
            errorMessage(vm, e.response.data.errors.error);
          } else {
            errorMessage(vm, vm.$t('error_alert_text'));
          }
        }
      });
    },
  },
  mounted() {
      this.getWorks();
      this.getEmployees();
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.modalData = newVal;
      },
      deep: true,
    },
    show: function(newVal) {
      if (newVal) {
        //load selected
      }
    },
    employee: {
      handler: function(newVal) {
        if(!newVal) {
          this.$set(this.filters, 'employee_uuid', null);
        }
      },
      deep: true,
    },
    work: {
      handler: function(newVal) {
        if(!newVal) {
          this.$set(this.filters, 'work_uuid', null);
        }
      },
      deep: true,
    },
  },
  validations() {
    return {
      modalData: {

      },
    }
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-header {
  display: flex;
  flex-direction: column;
}

.modal-container {
  width: 80%;
}

.unit-user-type span {
  padding: 10px;
  border-width: 1px;
  border-style: solid;
}

.fc {
  display: flex;
  justify-content: flex-end;

  button {
    height: 42px;
  }
}
</style>
