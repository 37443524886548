<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="title" id="work-list">
      <agro-filter-container :new-object="newObject" :title='$t("common.create_new")'>
        <s-c-work-group-filters ref="filters" @onFiltersApplied="onFiltersApplied"/>
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import SCWorkGroupFilters from "@/pages/salary-calculator/sc-work-group/partials/SCWorkGroupFilter";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'SCWorkGroupList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.scWorkGroupFilters ? this.scWorkGroupFilters : {},
      items: this.scWorkGroups ? this.scWorkGroups : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    SCWorkGroupFilters,
    AgroGrid,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      scWorkGroupFilters: 'scWorkGroup/scWorkGroupFilters',
      scWorkGroups: 'scWorkGroup/scWorkGroups',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    title() {
      return this.$t('salary_calculator.work_group.title');
    },
    config() {
      return {
        permission: permissions.SC_WORK_GROUP,
        permission_type: permissionTypes.module,
        edit: {
          url: '/salary-calculator/sc-work-group/{uuid}',
          callback: this.onItemEdited,
        },
        delete: {
          method: this.deleteObject,
          callback: this.onItemDeleted,
          disableIf: this.hasAssignedWorks,
          disableMessage: this.$t('common.delete_disabled')
        }
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        { name: this.$t('common.name'), col: 'name', width: '18%' },
        { name: this.$t('common.measure_unit'), col: 'measure_unit.full_name', width: '10%' },
        { name: this.$t('common.price'), col: 'price', suffix: this.$currency.name, width: '10%' },
        { name: this.$t('common.shift'), col: 'shift.full_link', width: '15%', align: 'center' },
        { name: this.$t('salary_calculator.work_group.assigned_works'), col: 'assigned_works', width: '10%', align: 'center' },
        { name: this.$t('salary_calculator.work_group.is_active_hours'), col: 'is_active_hours_label', width: '10%', align: 'center' },
        { name: this.$t('common.created_at'), col: 'created_at', width: '10%' },
        { name: this.$t('common.last_update'), col: 'updated_at', width: '13%' },
      ];
    },
  },
  methods: {
    ...mapActions({
      deleteObject: 'scWorkGroup/deleteSCWorkGroup',
    }),
    onPageSelected(page_id) {
      this.scWorkGroupFilters.page = page_id;
      this.$store.commit("scWorkGroup/"+type.SET_SC_WORK_GROUP_FILTERS, this.scWorkGroupFilters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: 'sc-work-group/add'
      });
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      console.log('onItemDeleted', item);
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    hasAssignedWorks(item) {
      return item.assigned_works > 0;
    }
  },
  watch: {
    scWorkGroups: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
