<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container">
        <div class="modal-header">
          <h3>{{ defaultTitleCaption ?? $t("tools.import.title") }}</h3>
        </div>
        <div class="modal-body">
          <div class="row" v-if="show_import_type_selector">
            <div class="col-lg-6">
              <label>{{ $t("select_import_type") }}</label>
              <b-form-select v-model="import_type" class="form-control" :options="import_type_list"></b-form-select>
            </div>
          </div>
          <div class="row justify-content-start pt-4">
            <unit-selector-d-d-l :inputUUID="selectedDepartmentUUID" @onChange="onDynamicFiltersChanged"
              ref="unitSelectorDepartment" />
          </div>
          <div class="row" v-if="
            currentImportType &&
            currentImportType.season_selector &&
            this.selectedDepartmentUUID != null
          ">
            <div class="col-lg-6">
              <label>{{ $t("tools.import.select_season") }}</label>
              <DxDropDownBox ref="seasonSelectBox" :value="gridBoxValue" :opened="isGridBoxOpened"
                :defer-rendering="false" :display-expr="gridBoxDisplayExpr" :show-clear-button="false"
                :data-source="seasonByDepartment" :remote-operations="false" :cache-enabled="true"
                @opened="isGridBoxOpened = true" @closed="isGridBoxOpened = false"
                drop-down-button-template="imageIcon">
                <template #imageIcon>
                  <img src="@/assets/images/icons/arrow_down.svg" class="custom-selector-icon" />
                </template>
                <template #content>
                  <DxDataGrid ref="seasonGrid" class="product_grid" :no-data-text="$t('tools.import.no_data_text')"
                    :data-source="seasonByDepartment" :show-column-headers="false" :hover-state-enabled="true"
                    :repaint-changes-only="true" :selected-row-keys="gridBoxValue"
                    @selection-changed="onGridSelectionChanged($event)" @content-ready="onGridContentReady($event)"
                    height="100%">
                    <DxToolbar v-if="allowGridAddNewSeason">
                      <DxItem location="before" template="collapseTemplate" />
                    </DxToolbar>
                    <template #collapseTemplate>
                      <b-button v-if="allowGridAddNewSeason" class="add_new_product_button" size="sm" pill
                        @click="addNewSeasonInstant"><img src="@/assets/images/icons/plus.svg" /></b-button>
                    </template>
                    <DxPopupGrid :show-title="true" :height="200"></DxPopupGrid>
                    <DxColumn data-field="name" caption="Denumire" placeholder="Denumire">
                      <DxRequiredRule :message="$t('tools.import.messages.name_required')" />
                    </DxColumn>
                    <DxSelection mode="single" />
                    <DxFilterRow :visible="true" />
                    <DxScrolling mode="infinite" />
                    <DxEditing :allow-adding="allowGridAddNewSeason" :onInitNewRow="addNewSeasonInstant" mode="popup">
                    </DxEditing>
                  </DxDataGrid>
                </template>
              </DxDropDownBox>
            </div>
          </div>
          <div class="row form-group pt-3" v-if="
            selectedDepartmentUUID &&
            currentImportType &&
            currentImportType.warehouse_selector
          ">
            <div class="col-sm-6 col-lg-6 form-group">
              <label class="font-weight-bold pt-3 d-block" v-html="$t('common.current_department') +
                ': ' +
                selectedDepartmentName
                "></label>
              <label class="control-label">{{ $t("common.warehouse") }}</label>
              <b-form-select v-model="selectedWarehouse" class="form-control" :options="warehouse_list"></b-form-select>
            </div>
          </div>
        </div>
        <div class="modal-footer col-lg-12">
          <div class="justify-content-end">
            <button :disabled="!checked" class="btn btn-success btn-upload-import" @click="upload">
              {{ defaultOkCaption ?? $t("upload") }}
            </button>
            <button class="modal-default-button btn btn-secondary" @click="cancel">
              {{ $t("common.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import { DxBox, DxItem } from "devextreme-vue/box";
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxFilterRow,
  DxScrolling,
  DxEditing,
  DxPopup as DxPopupGrid,
  DxRequiredRule,
  DxToolbar,
} from "devextreme-vue/data-grid";
import { importTypeList } from "~/helpers/ImportTypes";
import {
  buildDefaultDropdownList,
  buildDropdownOptions,
  lineToUnderscore,
} from "~/helpers/common";
import AgroFilter from "~/components/AgroFilter/Index.vue";
import UnitSelectorDDL from "~/components/AgroFilter/UnitSelectorDDL.vue";

export default {
  name: "ImportDepartmentSelector",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: Object,
    defaultOkCaption: {
      default: null,
    },
    defaultTitleCaption: {
      default: null,
    },
  },
  components: {
    UnitSelectorDDL,
    AgroFilter,
    DxDropDownBox,
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxFilterRow,
    DxScrolling,
    DxEditing,
    DxPopupGrid,
    DxRequiredRule,
    DxToolbar,
    DxBox,
    DxItem,
  },
  data: function () {
    return {
      modalData: this.data ? this.data : {},
      selectedDepartmentUUID: null,
      import_type: null,
      import_row_data: {},
      selected_season: "",
      gridBoxValue: [],
      isGridBoxOpened: false,
      allowGridAddNewSeason: false,
      seasonByDepartment: [],
      selectedWarehouse: "",
      show_import_type_selector: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      domain: "auth/domain",
      seasons: "seasons/seasons",
      warehouses: "smWarehouses/smWarehouses",
    }),
    checked() {
      if (!this.currentImportType) return false;
      if (
        this.currentImportType.warehouse_selector &&
        !this.selectedWarehouse
      ) {
        return false;
      }

      if (this.currentImportType.season_selector && !this.selected_season) {
        return false;
      }

      return this.selectedDepartmentUUID !== null;
    },
    departments() {
      if (this.user && this.user.is_superadmin == 1) {
        return this.$store.getters["company/departments"];
      } else if (this.user && this.user.departments) {
        return this.user.departments;
      } else {
        return [];
      }
    },
    import_type_list() {
      return buildDropdownOptions(
        importTypeList.map((item) => {
          item.title = this.$t(
            "tools.configurator." + this.lineToUnderscore(item.ref)
          );
          return item;
        }),
        {
          value: "id",
          text: "title",
        },
        {
          value: null,
          text: "-" + this.$t("select_import_type") + "-",
        }
      );
    },
    currentImportType() {
      if (this.import_type) {
        return importTypeList.find((itp) => itp.id === this.import_type);
      }

      return null;
    },
    warehouse_list() {
      return buildDefaultDropdownList(
        Object.assign([], this.warehouses),
        {
          value: "uuid",
          text: "name",
        },
        {
          value: null,
          text: "-" + this.$t("common.select_warehouse") + "-",
        }
      );
    },
    selectedDepartmentName() {
      if (this.selectedDepartmentUUID) {
        return this.departments.find(
          (d) => d.uuid === this.selectedDepartmentUUID
        ).name;
      }

      return (
        '<span class="text-danger">' +
        this.$t("tools.import.messages.unit_required") +
        "</span>"
      );
    },
  },
  methods: {
    ...mapActions({
      getDepartments: "company/getDepartments",
      getSeasons: "seasons/getSeasons",
      createSeasons: "seasons/createSeasons",
      getWarehouses: "smWarehouses/getSmWarehouses",
    }),
    reset() {
      this.selectedDepartmentUUID = null;
      this.import_type = null;
      this.import_row_data = {};
      this.selected_season = "";
      this.selectedWarehouse = "";
      this.show_import_type_selector = true;
      this.seasonByDepartment = [];
      this.gridBoxValue = [];
    },
    set(data) {
      this.reset();
      this.import_type = data.import_type;
      this.import_row_data = data;

      this.selectedDepartmentUUID = data.department_uuid;
      if (this.selectedDepartmentUUID) {
        this.$refs.unitSelectorDepartment.onChange(
          this.$refs.unitSelectorDepartment.getType("company"),
          this.selectedDepartmentUUID
        );
        this.onDynamicFiltersChanged(this.selectedDepartmentUUID);
      }

      this.selected_season = data.season_uuid;

      this.show_import_type_selector = data.show_import_type_selector;
    },
    upload: function () {
      if (this.checked) {
        this.import_row_data.import_type = this.import_type;
        this.import_row_data.department_uuid = this.selectedDepartmentUUID;
        this.import_row_data.season_uuid = this.selected_season;
        this.import_row_data.warehouse_uuid = this.selectedWarehouse;

        this.$emit("upload", this.import_row_data);
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    isCurrentDepartment(department) {
      if (department) {
        return this.selectedDepartmentUUID === department.uuid;
      }
      return false;
    },
    selectDepartment(department) {
      this.selectedDepartmentUUID = department.uuid;
      this.getSeasons({ department_uuid: this.selectedDepartmentUUID }).then(
        (seasons) => {
          this.$set(this, "seasonByDepartment", seasons.data);
        }
      );
    },
    gridBoxDisplayExpr(item) {
      return item && item.name;
    },
    onGridContentReady(e) {
      let season_visible_rows = e.component.getVisibleRows();

      if (season_visible_rows.length <= 0) {
        this.allowGridAddNewSeason = true;
      } else {
        this.allowGridAddNewSeason = false;
      }

      if(this.gridBoxValue.length <= 0 && this.selected_season && season_visible_rows.length > 0) {
        const selectedIndex = season_visible_rows.findIndex(row => row.data.uuid === this.selected_season);
        this.gridBoxValue.push(season_visible_rows[selectedIndex]['key']);
      }
    },
    onGridSelectionChanged(e) {
      this.gridBoxValue = e.currentSelectedRowKeys;
      this.isGridBoxOpened = false;
      if (this.gridBoxValue && this.gridBoxValue.length > 0) {
        this.selected_season = this.gridBoxValue[0].uuid;
      }
    },
    addNewSeasonInstant() {
      let gridFilter = this.$refs.seasonGrid.instance.getCombinedFilter();
      let seasonName =
        gridFilter && gridFilter.filterValue ? gridFilter.filterValue : "";
      this.createSeasons({
        department_uuid: this.selectedDepartmentUUID,
        company_uuid: this.domain.uuid,
        name: seasonName,
      }).then((response) => {
        this.getSeasons({ department_uuid: this.selectedDepartmentUUID }).then(
          (res) => {
            this.$refs.seasonSelectBox.instance.close();
            this.seasonByDepartment = this.seasons.filter(
              (obj) => obj.department_uuid == this.selectedDepartmentUUID
            );
            this.gridBoxValue = [];
            this.gridBoxValue.push(response.data);
          }
        );
      });
    },
    lineToUnderscore(value) {
      return lineToUnderscore(value);
    },
    onDynamicFiltersChanged(selectedUUID) {
      this.selectedDepartmentUUID = selectedUUID;

      if (selectedUUID) {
        this.selectDepartment(
          //this.departments.find(d => d.uuid === selectedUUID)
          {
            uuid: selectedUUID,
          }
        );
      }
    },
  },
  watch: {
    show: {
      handler: function (newVal) { },
    },
    selectedDepartmentUUID: function (newVal) {
      this.getWarehouses({
        company_uuid: this.domain.uuid,
        department_uuid: newVal,
        enablePagination: 0,
      });
    },
  },
  mounted() {
    this.getDepartments({
      company_uuid: this.domain.uuid,
    });
  },
};
</script>

<style lang="scss" scoped>
i {
  font-size: 32px;
  color: #ccc;
}

i:hover {
  color: #3b8070;
}

.selected {
  color: #3b8070;
}

.custom-selector-icon {
  width: 10px;
  padding-left: 0px;
  position: relative;
  left: 20px;
}

.btn-upload-import {
  margin-right: 15px;
}
</style>
