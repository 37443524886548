<template>
    <div>
        <DxDataGrid 
            :ref="tariffSystemGridRefKey"
            :data-source="tariffSystemItemDataSurce"
            :show-borders="true"
            :remote-operations="gridRemoteOperations"
            no-data-text="Nu există produse configurate în sistemul de tarifare"
        >
            <DxColumn
                data-field="product.uuid"
                caption="Produs"
            >
                <DxLookup
                    :data-source="productsData"
                    value-expr="uuid"
                    display-expr="name"
                />
                <DxRequiredRule message="Produsul este obligatoriu!"/>
            </DxColumn>
            <DxColumn
                data-field="quantity"
                data-type="string"
                caption="Cantitate"
            >
                <DxRequiredRule message="Anul este obligatoriu!"/>
            </DxColumn>
            <DxColumn
                data-field="measure_unit.uuid"
                caption="M.U."
            >
                <DxLookup
                    :data-source="measureUnitsData"
                    value-expr="uuid"
                    display-expr="name"
                />
                <DxRequiredRule message="Unitatea de masura este obligatorie!"/>
            </DxColumn>
            <DxPaging :page-size="15"/>
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                display-mode="compact"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
            />
            <DxEditing
                refresh-mode="full"
                :allow-adding="true"
                :allow-updating="true"
                :allow-deleting="true"
                :use-icons="true"
                mode="row"
            >
                <DxTexts
                    confirm-delete-message="Sigur vreți să ștergeți produsul din sistemul de tarifare?",
                    confirm-delete-title="Confirmare ștergere"
                />
            </DxEditing>
        </DxDataGrid>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import * as endpoints from '../../../store/api/endpoints'
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule } from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import Forbidden from "~/pages/errors/Forbidden.vue";
import * as api from '../../../store/api/index'

export default {
    name: "TariffSystemItems",
    middleware: ['auth', 'check-company-admin'],
    data(){
        return {
            tariffSystemGridRefKey: 'tariff-system-item-grid-' + this.templateData.key,
            tariffSystemItemDataSurce: new CustomStore({
                key: 'uuid',
                load: this.loadTariffSystemItems,
                insert: this.insert,
                update: this.update,
                remove: this.delete
            }),
            gridRemoteOperations: {
                paging: true,
                filtering : true,
                sorting: true
            },
            productsData: [],
            measureUnitsData: [],
            pageSizes: [15, 25, 100]
        };
    },
    props:{
        templateData: {
            type: Object,
            default: null
        }
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        DxEditing,
        DxTexts,
        DxLookup,
        DxRequiredRule,
        Forbidden
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            users: 'user/users',
        }),
        canEdit() {
            return canEdit(this);
        },
        tariffSystemGrid: function() {
            return this.$refs[tariffSystemGridRefKey].instance;
        },
    },
    methods: {
        ...mapActions({
            getProducts: 'leasecontracts/getProducts',
            geMeasureUnits: 'leasecontracts/geMeasureUnits',
            createTariffSystemItem: 'leasecontracts/createTariffSystemItem',
            updateTariffSystemItem: 'leasecontracts/updateTariffSystemItem',
            deleteTariffSystemItem: 'leasecontracts/deleteTariffSystemItem',
            getTariffSystemItemByUUID: 'leasecontracts/getTariffSystemItemByUUID',
        }),
        async loadTariffSystemItems(loadOptions){
            let pageLimit = loadOptions.take;
                let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
                
                let dataSource =  {
                    data:[],
                    totalCount:0
                };
                let payload = {
                    page: pageNumber,
                    limit: pageLimit,
                    tariff_system_uuid: this.templateData.data.uuid,
                    sortBy: 'uuid'
                }
                await api.fetchFull(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM_ITEM, payload, null,(response) => {
                    dataSource.data = response.data.data;
                    dataSource.totalCount = response.data.pagination.total
                });

                return dataSource;
        },
        async insert(obj){
             obj.tariff_system_uuid = this.templateData.data.uuid;
             obj.product_uuid = obj.product.uuid;
             obj.measure_unit_uuid = obj.measure_unit.uuid;
            await this.createTariffSystemItem(obj);
        },
        async update(key, obj){
            let oldObj = {
                tariff_system_item_uuid: key,
                uuid: key,
                tariff_system_uuid: this.templateData.data.uuid,
                product_uuid: '',
                quantity: 0,
                measure_unit_uuid: ''
            };
            await this.getTariffSystemItemByUUID({uuid: key}).then(async response => {
                oldObj.product_uuid = obj.product ? obj.product.uuid : response.data.product.uuid;
                oldObj.quantity = obj.quantity ? obj.quantity : response.data.quantity;
                oldObj.measure_unit_uuid = obj.measure_unit ? obj.measure_unit.uuid : response.data.measure_unit.uuid;
                await this.updateTariffSystemItem(oldObj);
            });
            
        },
        async delete(key){
            let obj = {uuid: key, product_price_uuid: key}
            await this.deleteTariffSystemItem(obj);
        }
    },
    mounted(){
        this.getProducts().then(response => {
             this.productsData = response.data;
        });
        this.geMeasureUnits().then(response => {
            this.measureUnitsData = response.data;
        });
    }
}

</script>