<template>
  <div class="import">
    <div class="import-holder" v-if="canEdit">
      <card :title="$t('recently_uploaded_files')" id="recently-uploaded">
        <file-list ref="importFileList" :data="recently_uploaded_files" @importValidatedDataEvent="importValidatedData"></file-list>
      </card>

      <card :title="$t('upload_file') + ' ' + $t('allowed_extensions') + ' ' + $t('allowed_extensions_for_shape')"
        :title-font-size="18" container-class="p-3" id="file-uploader" class="mt-3 pl-0">
        <file-uploader ref="file_uploader" :config="uploadConfig" :params="requestParams"
          @onFileSelected="onFileSelected" @onFileUploaded="onFileUploaded"
          @onFileUploadError="onFileUploadError"></file-uploader>
      </card>
      <import-department-selector ref="importDepartmentSelector" :show="showDepartmentSelector"
        @upload="persistFileUpload" @cancel="cancelDepartmentSelector"></import-department-selector>
    </div>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>

<script>
import FileList from "./partials/FileList";
import ImportDepartmentSelector from "./partials/ImportDepartmentSelector";
import FileUploader from "../../../modules/FileUploader/FileUploader.vue";
import { generateUUID, getServerSubdomain } from "@/helpers/common";
import { canEdit, permissions, permissionTypes } from "@/helpers/Permissions";
import Forbidden from "@/pages/errors/Forbidden";
import * as api from '../../../store/api/endpoints'
import {
  successMessage,
  errorMessageList,
} from "~/helpers/common";
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";

export default {
  name: 'Import',
  data() {
    return {
      recently_uploaded_files: this.recentlyUploadedFiles ? this.recentlyUploadedFiles : [],
      showDepartmentSelector: false,
      selectedDepartmentUUID: null,
      isHistoryGridItem: false,
      warehouseUUID: null,
      company: null,
      uploadConfig: {},
      requestParams: {},
      intervalId: null, // Store the interval ID here
    }
  },
  computed: {
    ...mapGetters({
      recentlyUploadedFiles: 'company/recentlyUploadedFiles',
    }),
    config() {
      return {
        permission: permissions.IMPORT,
        permission_type: permissionTypes.module,
      };
    },
    canEdit() {
      return canEdit(this);
    },
    sessionID() {
      return generateUUID();
    },
  },
  components: {
    FileUploader,
    FileList,
    ImportDepartmentSelector,
    Forbidden
  },
  methods: {
    ...mapActions({
      getRecentlyUploadedFiles: 'company/getRecentlyUploadedFiles',
      transferValidatedDataToLiveData: "importConfig/transferValidatedDataToLiveData",
    }),
    onFileUploaded(file) {
      this.getUploadedFiles();
    },
    onFileUploadError(file) {
      this.getUploadedFiles();
    },
    onFileSelected(file) {
      this.selectedDepartmentUUID = null;
      this.$refs.importDepartmentSelector.reset();
      this.showDepartmentSelector = true;
      this.isHistoryGridItem = false;
    },
    getUploadedFiles(company) {
      if (company && company.uuid) {
        this.getRecentlyUploadedFiles({
          company_uuid: company.uuid,
        })
      }
    },
    persistFileUpload(data) {
      if(this.isHistoryGridItem){
        this.startValidatedDataImport(data);
      } else {
        this.showDepartmentSelector = false;
        this.selectedDepartmentUUID = data.department_uuid;
        this.warehouseUUID = data.warehouse_uuid;
        this.$refs.file_uploader.upload(data);
      }
    },
    cancelDepartmentSelector() {
      this.$refs.file_uploader.clearSelectedFiles();
      this.showDepartmentSelector = false;
      this.$refs.importFileList.setItemToLiveData(null);
    },
    setUploadConfig(company) {
      this.uploadConfig = {
        upload_url: api.replaceParams(
          api.FILE_UPLOAD_URL,
          {
            company_uuid: company.uuid,
            session_id: this.sessionID,
            warehouse_uuid: this.warehouseUUID,
          }
        ),
        allowed_extensions: '.csv, .txt, .xls, .xlsx, .zip',
      };
    },
    importValidatedData(data) {
      data.show_import_type_selector = false;
      this.$refs.importDepartmentSelector.set(data);
      this.showDepartmentSelector = true;
      this.isHistoryGridItem = true;
    },
    startValidatedDataImport(transferData) {
      this.showDepartmentSelector = false;

      const transfer_settings = {
        import_id: transferData.import_id,
        import_type: transferData.import_type,
        department_uuid: transferData.department_uuid,
        warehouse_uuid: transferData.warehouse_uuid,
        season_uuid: transferData.season_uuid,
        source_data_type: transferData.source_data_type,
        uploaded_file_id: transferData.uploaded_file_id,
        session_id: transferData.session_id,
        filename: transferData.filename,
      };

      let vm = this;
      this.transferValidatedDataToLiveData(transfer_settings)
        .then((response) => {
          successMessage(vm, response.message);
          this.$refs.importFileList.updateProcessedAfterValidation(transferData.import_id, 1);
          this.$refs.importFileList.setItemToLiveData(null);
        })
        .catch((e) => {
          errorMessageList(vm, e.errors);
          this.$refs.importFileList.setItemToLiveData(null);
        });
    },
  },
  async mounted() {
    let vm = this;
    const dom = await this.$store.dispatch('auth/checkDomain', {
      domain: Cookies.get('domain')
    });
    this.company = dom;
    this.setUploadConfig(dom);
    vm.getUploadedFiles(dom);
    this.intervalId = setInterval(function () {
      vm.getUploadedFiles(vm.company);
    }, 10000);
  },
  beforeDestroy() { // Use beforeUnmount() for Vue 3
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  watch: {
    recentlyUploadedFiles: {
      handler: function (newVal) {
        this.recently_uploaded_files = newVal;
      },
      deep: true,
    }
  }
}
</script>
