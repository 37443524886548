<template>
    <div>
        <card title="Sisteme de tarifare"></card>
        <div class="content-body pt-4">
            <div class="row">
                <div class="col-lg-12">
                    <DxDataGrid 
                        :ref="tariffSystemGridRefKey"
                        :data-source="prodDataSurce"
                        :show-borders="true"
                        :remote-operations="gridRemoteOperations"
                    >
                        <DxColumn
                            data-field="name"
                            data-type="string"
                        />
                        <DxPaging :total="3500" :page-size="15"/>
                        <DxPager
                            :visible="true"
                            :allowed-page-sizes="pageSizes"
                            display-mode="compact"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                        />
                        <DxEditing
                            :allow-adding="true"
                            :allow-updating="true"
                            :allow-deleting="true"
                            :use-icons="true"
                            mode="row"
                        >
                            <DxTexts
                                confirm-delete-message="Sigur vreți să ștergeți sistemul de tarifare?",
                                confirm-delete-title="Confirmare ștergere"
                            />
                        </DxEditing>
                        <DxSelection mode="single" />
                        <DxMasterDetail :enabled="true" template="masterDetailTemplate"/>
                        <template #masterDetailTemplate="{ data: tariffSystem }">
                            <TariffSystemItems :templateData="tariffSystem" />
                        </template>
                    </DxDataGrid>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as endpoints from '../../../store/api/endpoints'
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxMasterDetail, DxSelection } from 'devextreme-vue/data-grid';
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import axios from '@/plugins/axios'
import * as api from '../../../store/api/index'
import TariffSystemItems from './tariffSystemItems';

export default {
    name: "ArendaTariffSystems",
    middleware: ['auth', 'check-company-admin'],
    data(){
        return {
            tariffSystemGridRefKey: 'tariff-system-grid',
            prodDataSurce: new CustomStore({
                key: 'uuid',
                async load(loadOptions){

                    let pageLimit = loadOptions.take;
                    let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
                    
                    let dataSource =  {
                        data:[],
                        totalCount:0
                    };
                    let payload = {
                        page: pageNumber,
                        limit: pageLimit,
                        
                    }
                    await api.fetchFull(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM, payload, null,(response) => {
                        dataSource.data = response.data.data;
                        dataSource.totalCount = response.data.pagination.total
                    });

                    return dataSource;
                },
                insert: this.insert,
                update: this.update,
                remove: this.delete
            }),
            gridRemoteOperations: {
                paging: true,
                filtering : true,
                sorting: true
            },
            products: [],
            pageSizes: [15, 25, 100]
        };
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        DxEditing,
        DxTexts,
        DxMasterDetail,
        DxSelection,
        Forbidden,
        TariffSystemItems
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            users: 'user/users',
        }),
        canEdit() {
            return canEdit(this);
        },
        tariffSystemGrid: function() {
            return this.$refs[tariffSystemGridRefKey].instance;
        },
    },
    methods: {
        ...mapActions({
            getProducts: 'leasecontracts/getProducts',
            createTariffSystem: 'leasecontracts/createTariffSystem',
            updateTariffSystem: 'leasecontracts/updateTariffSystem',
            deleteTariffSystem: 'leasecontracts/deleteTariffSystem',
        }),
        async insert(obj){
            await this.createTariffSystem(obj);
        },
        async update(key, obj){
            obj.uuid = key;
            obj.tariff_system_uuid = key;
            await this.updateTariffSystem(obj);
        },
        async delete(key){
            let obj = {uuid: key, product_price_uuid: key}
            await this.deleteTariffSystem(obj);
        }
    },
}

</script>