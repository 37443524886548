const state = () => {
  return {
    culture: {},
    cultures: [],
    varieties: [],
    variety: {},
    culture_filters: {},
  }
}

export default state;
