<template>
  <div class="manual-work-dialog">
    <DxPopup
      id="dx-dialog"
      width="100vw"
      height="100vh"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      :resize-enabled="false"
      :darg-enabled="false"
      @hidden="$emit('hidden')"
      :wrapper-attr="popupAttributes"
    >
      <template #title>
        <div class="manual-work-dialog-title" v-if="company_use_datallers == true || currentStep > 1">
          <div class="left_side">
            <b-button
              v-if="currentStep > (company_use_datallers ? 0 : 1)"
              class="left_title_button"
              variant="outline-secondary"
              size="sm"
              pill
              @click="backButtonEvent"
              ><img src="@/assets/images/icons/arrow-back.svg" />
              Inapoi</b-button
            >
          </div>
          <div class="right_side">
            <img
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCloseEvent"
            />
          </div>
          <div class="right_side" v-if="false">inainte</div>
        </div>
      </template>
      <template #content>
        <div
          class="manual-work-tabs"
          style="width: 100%; display: flex; flex-direction: column"
        >
          <DxTabPanel
            :element-attr="tabPanelAttributes"
            :data-source="workSteps"
            :loop="false"
            :selected-item="currentItem"
            :selected-index="currentStep"
            :animation-enabled="true"
            :swipe-enabled="false"
            :show-nav-buttons="false"
            @selection-changed="tabSelectionChanged($event)"
          >
            <template #item="{ data: step }">
              <div>
                <div class="manual-work-title">
                  <div>
                     <div class="step_title" v-if="step == 0">
                      Adaugă lucrare
                    </div>
                    <div class="step_title" v-if="step != 0 && step != 3 && step != 4 && (company_use_datallers == true || step == 2 )">
                      {{titleByZilier}}
                    </div>
                    <div class="step_title_with_close" v-if="step == 1 && company_use_datallers == false" >
                      <div class="step_title" v-if="step != 0 && step != 3 && step != 4">
                       {{titleByZilier}}
                      </div>
                      <img
                        src="@/assets/images/icons/dialog-close.svg"
                        @click="buttonCloseEvent"
                      />
                    </div>
                    <div class="step_subtitle" v-if="step == 1">
                      {{ getSelectedParcelSurface
                      }}<span style="color: #096c27">{{
                        getSelectedParcelCount
                      }}</span>
                    </div>
                    <div class="step_title" v-if="step == 3">
                      {{ detailStepTitle }}
                    </div>
                    <div class="step_title" v-if="step == 4">
                      {{ detailAddStepTitle }}
                    </div>
                  </div>
                  <div>
                    <b-button v-if="step == 3 && detail_step_code != 'PARCELS' && detail_step_code != 'WORK_DATE'"
                      class="manual-work-add-button"
                      variant="outline-secondary"
                      size="sm"
                      pill
                      @click="navigateToDetailAdd"
                      ><img src="@/assets/images/icons/plus.svg"
                    /></b-button>
                  </div>
                </div>
               <div v-if="step == 0 && company_use_datallers" class="step">
                  <div class="step_1">
                    <div class="step_1_container">
                      <DxRadioGroup
                        :items="workTypeOptions"
                        item-template="item"
                        class="article_type_selector"
                        v-model="currentWorkOption"
                      >
                        <template #item="{ data }">
                          <div
                            :class="[
                              'article_item', 'article_item_selected'
                            ]"
                          >

                            {{ data.name }}
                          </div>
                        </template>
                      </DxRadioGroup>
                    </div>
                  </div>
               </div>
                <div v-show="step == 1" :class="company_use_datallers ? 'step' : 'step_map_no_zilier'">
                  <google-map
                    ref="googlemap"
                    :showMap="step == 1"
                    :selection_mode="true"
                    :initMapInMount="true"
                    :css_title_top="100"
                    @showToastMessage="showToastMessage"
                    @removeSelectedParcel="removeSelectedParcel"
                    @addSelectedParcel="addSelectedParcel"
                    testMessage="MAPP FROM ADD WORK"
                  >
                  </google-map>

                  <div
                    v-if="selectedLayers.length > 0"
                    class="continue_manual_work_map_sticky_dialog"
                  >
                    <b-button
                      class="continue_button"
                      variant="outline-secondary"
                      size="sm"
                      pill
                      @click="continueFromMap"
                      >Continuă</b-button
                    >
                  </div>
                </div>
                <div v-if="step == 2" class="step">
                  <DxScrollView class="step2_scroll_container" :use-native="true">
                  <div
                    class="step-item"
                    @click="detailStepClick('WORK_DATE')"
                    :class="[{step_control_error: $v.manualWorkData.work_date.$error}]"
                >
                    <div class="step-item-title">Data</div>
                    <div :class="manualWorkData.work_date && manualWorkData.work_date.length > 0 ? 'step-item-value' : 'step-item-value-placeholder'">
                      {{workDateText}}
                      <img
                          class="main_filter_right_arrow"
                          src="@/assets/images/icons/right_arrow_dg.svg"

                        />
                      <DxDateBox v-if="false" class="manual-work-input" type="date" />
                    </div>
                  </div>
                  <div v-show="isZilierWork"
                    class="step-item"
                    :class="[{step_control_error: $v.manualWorkData.zilierCount.$error}]"
                  >
                    <div class="step-item-title">Oameni (pers.)</div>
                    <div class="step-item-value">
                      <DxNumberBox
                          placeholder="Oameni (pers.)"
                          :format=" { type: 'fixedPoint', precision: 0 }"
                          v-model="manualWorkData.zilierCount"
                          class="manual-work-input"
                          :show-clear-button="false"
                        />
                    </div>
                  </div>
                  <div v-show="!isZilierWork"
                    class="step-item"
                    @click="detailStepClick('WORK_MACHINE')"
                    :class="[{step_control_error: $v.manualWorkData.workMachine_UUID.$error}]"
                  >
                    <div class="step-item-title">Tractor</div>
                    <div :class="manualWorkData.workMachine_UUID && manualWorkData.workMachine_UUID.length > 0 ? 'step-item-value' : 'step-item-value-placeholder'">
                      {{ workMachineName }}
                      <img
                          class="main_filter_right_arrow"
                          src="@/assets/images/icons/right_arrow_dg.svg"

                        />
                      <dx-combo-with-add v-if="false"
                        ref="comboWorkMashines"
                        :comboDataset="workMachines"
                        addNewText="Tractorul nu a fost găsit"
                        @addNewItemInstant="addNewTractor"
                      ></dx-combo-with-add>
                    </div>
                  </div>
                  <div v-show="!isZilierWork"
                    class="step-item"
                    @click="detailStepClick('WORK_UTILITY')"
                  >
                    <div class="step-item-title">Utilaj</div>
                    <div :class="manualWorkData.workUtility_UUID && manualWorkData.workUtility_UUID.length > 0 ? 'step-item-value' : 'step-item-value-placeholder'">
                      {{ workUtilityName }}
                      <img
                          class="main_filter_right_arrow"
                          src="@/assets/images/icons/right_arrow_dg.svg"

                        />
                      <dx-combo-with-add
                        v-if="false"
                        ref="comboUtilaje"
                        :comboDataset="workMachines"
                        addNewText="Utilajul nu a fost găsit"
                        @addNewItemInstant="addNewUtilaj"
                      ></dx-combo-with-add>
                    </div>
                  </div>
                  <div
                    class="step-item"
                    @click="detailStepClick('WORK_TYPE')"
                    :class="[{step_control_error: $v.manualWorkData.workType_UUID.$error}]"
                  >
                    <div class="step-item-title">Lucrare</div>
                    <div :class="manualWorkData.workType_UUID && manualWorkData.workType_UUID.length > 0 ? 'step-item-value' : 'step-item-value-placeholder'">
                      {{ workTypeName }}
                      <img
                          class="main_filter_right_arrow"
                          src="@/assets/images/icons/right_arrow_dg.svg"

                        />
                      <dx-combo-with-add
                        v-if="false"
                        ref="comboWorkTypes"
                        :comboDataset="workTypes.filter(obj => obj.dataller == 1)"
                        addNewText="Lucrarea nu a fost găsită"
                        @addNewItemInstant="addNewWorkType"
                      ></dx-combo-with-add>
                    </div>
                  </div>
                  <div v-show="isZilierWork"
                    class="step-item"
                    :class="[{step_control_error: $v.manualWorkData.zilierSalary.$error}]"
                  >
                    <div class="step-item-title">Salariu (Ron)</div>
                    <div class="step-item-value">
                      <DxNumberBox
                          placeholder="Salariu (Ron)"
                          :format=" { type: 'decimal', precision: 2 }"
                          v-model="manualWorkData.zilierSalary"
                          class="manual-work-input"
                          :show-clear-button="false"
                        />
                    </div>
                  </div>
                  <div
                    class="step-item"
                    @click="detailStepClick('PARCELS')"
                    >
                    <div class="step-item-title">Parcele</div>
                    <div class="step-item-value">
                        {{ getSelectedParcelSurfaceSimple }}
                        {{ getSelectedParcelCount }}
                        <img
                          class="main_filter_right_arrow"
                          src="@/assets/images/icons/right_arrow_dg.svg"

                        />
                    </div>
                  </div>
                  <div class="step-item">
                    <div class="step-item-title">Observații</div>
                    <div class="step-item-value">
                        <DxTextArea v-model="manualWorkData.notes" class="global_dx_number_input" />
                    </div>
                  </div>
                  <div class="bottom-spacer"></div>
                  </DxScrollView>
                  <div class="continue_manual_work_data_sticky_dialog">
                    <b-button
                      class="continue_button"
                      variant="outline-secondary"
                      size="sm"
                      pill
                      @click="saveAndAddProduct"
                      >Salvează și adaugă produs</b-button
                    >
                    <b-button
                      class="continue_button second_action_button"
                      variant="outline-secondary"
                      size="sm"
                      pill
                      @click="saveOnly"
                      >Salvează</b-button
                    >
                  </div>
                </div>
                <div v-if="step == 3" class="step">
                  <div v-if="detail_step_code == 'WORK_DATE'" class="detail-step">
                      <functional-calendar v-if="showPopup"
                      :date-format="'yyyy-mm-dd'"
                      :is-date-range="false"
                      :is-date-picker="true"
                      v-model="calendar"
                      :change-month-function="true"
                      :change-year-function="true"
                    ></functional-calendar>
                  </div>
                  <div v-if="detail_step_code == 'WORK_MACHINE'" class="detail-step">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută tractor"
                        v-model="instantFilters.workMachine"
                      ></b-form-input>
                    </b-input-group>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <b-form-radio
                          name="work-machine-radios"
                          class="checkbox_row"
                          v-for="(machine, index) in (workMachines
                            ? workMachines
                            : []
                          ).filter((obj) => {
                            return (obj.autopropulsat == true ) &&
                            (obj.is_dataller == null || obj.is_dataller === undefined || obj.is_dataller == 0 || obj.is_dataller == false ) &&
                            obj.name
                              .toUpperCase()
                              .includes(instantFilters.workMachine.toUpperCase());
                          }
                          )"
                          :key="index"
                          v-model="manualWorkData.workMachine_UUID"
                          :value="machine.uuid"
                          @change="changeSelectedTractor($event)"
                        >
                          {{ machine.name }}
                        </b-form-radio>

                      </div>
                    </DxScrollView>
                  </div>
                  <div v-if="detail_step_code == 'WORK_UTILITY'" class="detail-step">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută utilaj"
                        v-model="instantFilters.workUtility"
                      ></b-form-input>
                    </b-input-group>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <b-form-radio
                          name="work-machine-radios"
                          class="checkbox_row"
                          v-for="(utility, index) in (workMachines
                            ? workMachines
                            : []
                          ).filter((obj) => {
                            return (obj.autopropulsat == undefined || obj.autopropulsat == null || obj.autopropulsat == false ) &&
                            (obj.is_dataller == null || obj.is_dataller === undefined || obj.is_dataller == 0 || obj.is_dataller == false ) &&
                            obj.name
                              .toUpperCase()
                              .includes(instantFilters.workUtility.toUpperCase());
                          }
                          )"
                          :key="index"
                          v-model="manualWorkData.workUtility_UUID"
                          :value="utility.uuid"
                          @change="changeSelectedUtility($event)"
                        >
                          {{ utility.name }}
                        </b-form-radio>

                      </div>
                    </DxScrollView>
                  </div>
                  <div v-if="detail_step_code == 'WORK_TYPE'" class="detail-step">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută lucrare"
                        v-model="instantFilters.workType"
                      ></b-form-input>
                    </b-input-group>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <b-form-radio
                          name="work-machine-radios"
                          class="checkbox_row"
                          v-for="(iWork, index) in (workTypes
                            ? workTypes.filter(obj => obj.dataller == isZilierWork)
                            : []
                          ).filter((obj) =>
                            obj.name
                              .toUpperCase()
                              .includes(instantFilters.workType.toUpperCase())
                          )"
                          :key="index"
                          v-model="manualWorkData.workType_UUID"
                          :value="iWork.uuid"
                          @change="changeSelectedWorkType($event)"
                        >
                          {{ iWork.name }}
                        </b-form-radio>

                      </div>
                    </DxScrollView>
                  </div>
                  <div v-if="detail_step_code == 'PARCELS'" class="detail-step">

                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <div class="parcel_item" v-for="(layer, index) in selectedLayers
                        ? selectedLayers
                        : []"
                      :key="index">
                            <div>{{layer.name}}</div>
                            <DxNumberBox
                                v-model="layer.surface"
                                class="manual-work-input-right"
                                :show-clear-button="false"
                                format="#0.## ha"
                              />
                        </div>
                      </div>
                    </DxScrollView>
                  </div>
                   <div class="apply_manual_work_parcels">
                    <b-button
                      class="continue_button"
                      variant="outline-secondary"
                      size="sm"
                      pill
                      @click="applyParcelChanges"
                      >Aplică</b-button
                    >
                  </div>
                </div>
                <div v-if="step == 4" class="step">
                  <div
                    class="step-item"
                    :class="[{step_control_error: $v.newTractorName.$error}]"
                    v-if="detail_step_code == 'WORK_MACHINE'" >
                    <div class="step-item-title">Nume</div>
                    <div class="step-item-value">
                      <DxTextBox class="manual-work-input" v-model="newTractorName" placeholder="Denumire tractor"/>
                      <b-button
                      class="manual-work-add-button-save"
                      variant="outline-secondary"
                      size="sm"
                      pill
                      v-model="newTractorName"
                      @click="saveTractor"
                      ><img src="@/assets/images/icons/plus_green.svg"
                    /></b-button>
                    </div>
                  </div>
                  <div
                    class="step-item"
                    :class="[{step_control_error: $v.newUtilityName.$error}]"
                    v-if="detail_step_code == 'WORK_UTILITY'" >
                    <div class="step-item-title">Nume</div>
                    <div class="step-item-value">
                      <DxTextBox class="manual-work-input" v-model="newUtilityName" placeholder="Denumire utilaj"/>
                      <b-button
                      class="manual-work-add-button-save"
                      variant="outline-secondary"
                      size="sm"
                      pill
                      v-model="newUtilityName"
                      @click="saveUtility"
                      ><img src="@/assets/images/icons/plus_green.svg"
                    /></b-button>
                    </div>
                  </div>
                  <div
                    class="step-item"
                    :class="[{step_control_error: $v.newWorkTypeName.$error}]"
                    v-if="detail_step_code == 'WORK_TYPE'" >
                    <div class="step-item-title">Nume</div>
                    <div class="step-item-value">
                       <DxTextBox class="manual-work-input" v-model="newWorkTypeName" placeholder="Denumire lucrare"/>
                      <b-button
                      class="manual-work-add-button-save"
                      variant="outline-secondary"
                      size="sm"
                      pill
                      v-model="newWorkTypeName"
                      @click="saveWorkType"
                      ><img src="@/assets/images/icons/plus_green.svg"
                    /></b-button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </DxTabPanel>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import Works from "../partials/works.vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxTabPanel from "devextreme-vue/tab-panel";
import GoogleMap from "@/components/Map/GoogleMap.vue";
import DxDateBox from "devextreme-vue/date-box";
import DxSelectBox from "devextreme-vue/select-box";
import { required, helpers } from "vuelidate/src/validators";
import DxComboWithAdd from "@/components/Mobile/DxComboWithAdd/index.vue";
import DxNumberBox from "devextreme-vue/number-box";
import { FunctionalCalendar } from "vue-functional-calendar";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxTextBox from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';

const requiredZilier = (param) => (value) => {
  if(param){
    return helpers.req(value) && value > 0;
  }
  return true;
};

const requiredNotZilier = (param) => (value) => {
  if(!param){
    return helpers.req(value);
  }
  return true;
};

export default {
  name: "manualWorkDialog",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    templateData: {
      type: Object,
      default: () => {},
    },
    selectedLayer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tabPanelAttributes: {
        class: 'manual-work-tabs'
      },
      captures: [],
      imgReport: [],
      frontCam: false,
      webcam: null,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',

      season_uuid_local: '',
      isZilierWork: true,
      workSteps: [0, 1, 2, 3, 4, 5],
      currentStep: 0,
      currentItem: 0,
      detail_step_code: "",
      selectedLayers: [],
      calendar: {},
      manualWorkData: {
        work_date: "",
        workMachine_UUID: "",
        workMachine_name: "",
        workUtility_UUID: "",
        workUtility_name: "",
        workType_UUID: "",
        workType_name: "",
        zilierCount: null,
        zilierSalary: null,
        notes:''
      },
      instantFilters: {
        workMachine: "",
        workUtility: "",
        workType: "",
        parcel: ""
      },
      workMachines: [],
      workTypes: [],
      newTractorName:'',
      newUtilityName: '',
      newWorkTypeName: '',
      workTypeOptions: [
        { id: 1, name: "Lucare cu tractor", code: "MACHINE_WORK" },
        { id: 2, name: "Lucrare cu oameni", code: "HUMAN_WORK" }
      ],
      currentWorkOption: null,
      worksHasMachines:[],
      company_use_datallers: false
    };
  },
  validations() {
    return {
      newTractorName: {
        required,
      },
      newUtilityName: {
        required,
      },
      newWorkTypeName: {
        required,
      },
      manualWorkData: {
        work_date: {
          required,
        },
        workMachine_UUID: {
          requiredNotZilier: requiredNotZilier(this.isZilierWork),
        },
        workType_UUID: {
          required,
        },
        zilierCount: {
          requiredZilier: requiredZilier(this.isZilierWork)
        },
        zilierSalary: {
          requiredZilier: requiredZilier(this.isZilierWork)
        }
      },
    };
  },
  components: {
    DxPopup,
    DxPosition,
    Works,
    DxScrollView,
    DxTabPanel,
    GoogleMap,
    DxDateBox,
    DxSelectBox,
    DxComboWithAdd,
    DxNumberBox,
    FunctionalCalendar,
    DxRadioGroup,
    DxTextBox,
    DxTextArea,
  },
  computed: {
    ...mapGetters({
      layers: "layers/layers",
    }),
    currentDepartment() {
      return this.$store.getters["auth/currentDepartment"] ?? {};
    },
    popupAttributes(){
      if(this.company_use_datallers == false && this.currentStep == (this.company_use_datallers ? 0 : 1)){
        return  {
          class: 'manual-work-popup-simple'
        };
      }
      else {
        return  {
          class: 'manual-work-popup'
        };
      }
    },
    allowSwipe() {
      return this.currentStep > 0;
    },
    getSelectedParcelSurfaceDecimal() {
      return (this.selectedLayers && this.selectedLayers.length > 0 ? this.selectedLayers.map(l => l.surface).reduce((x, y) => { return (parseFloat(x) + parseFloat(y)).toFixed(2);}) : 0);
    },
    getSelectedParcelSurface() {
      return "Ai selectat " + this.getSelectedParcelSurfaceDecimal + " hectare cu ";
    },
    getSelectedParcelSurfaceSimple() {
      return this.getSelectedParcelSurfaceDecimal + " hectare cu ";
    },
    getSelectedParcelCount() {
      return (
        "" +
        this.selectedLayers.length +
        (this.selectedLayers.length == 1 ? " parcelă" : " parcele")
      );
    },
    workDateText(){
      if (
        this.manualWorkData?.work_date
      ) {
        return this.manualWorkData.work_date;
      }
      return "Selectează data!";
    },
    workMachineName() {
      if (
        this.manualWorkData?.workMachine_UUID &&
        this.manualWorkData?.workMachine_name
      ) {
        return this.manualWorkData.workMachine_name;
      }
      return "Selectează tractor!";
    },
    workUtilityName() {
      if (
        this.manualWorkData?.workUtility_UUID &&
        this.manualWorkData?.workUtility_name
      ) {
        return this.manualWorkData.workUtility_name;
      }
      return "Selectează utilaj!";
    },
    workTypeName(){
      if (
        this.manualWorkData?.workType_UUID &&
        this.manualWorkData?.workType_name
      ) {
        return this.manualWorkData.workType_name;
      }
      return "Selectează lucrare!";
    },
    detailStepTitle() {
      if (this.detail_step_code == "WORK_DATE") {
        return "Data";
      }
      if (this.detail_step_code == "WORK_MACHINE") {
        return "Tractoare";
      }
      if (this.detail_step_code == "WORK_UTILITY") {
        return "Utilaje";
      }
      if (this.detail_step_code == "WORK_TYPE") {
        return "Lucrare";
      }
      if (this.detail_step_code == "PARCELS") {
        return "Parcele";
      }
    },
    detailAddStepTitle() {
      if (this.detail_step_code == "WORK_MACHINE") {
        return "Adaugă tractor nou";
      }
      if (this.detail_step_code == "WORK_UTILITY") {
        return "Adaugă utilaj nou";
      }
      if (this.detail_step_code == "WORK_TYPE") {
        return "Adaugă lucrare nouă";
      }
      return "";
    },
    titleByZilier(){
      return "Adaugă lucrare cu " + (this.isZilierWork ? "oameni" : "tractor");
    }
  },
  watch: {
    calendar: {
      handler(newVal) {
        if(newVal?.selectedDate){
          this.manualWorkData.work_date = newVal.selectedDate;
        }
      },
      deep: true,
    },
    currentWorkOption(newVal){
      if(newVal != null){
       if(newVal.code == 'MACHINE_WORK'){
          this.isZilierWork = false;
       }
       if(newVal.code == 'HUMAN_WORK'){
          this.isZilierWork = true;
       }
       this.currentStep = this.currentStep + 1;
      }
    },
    showPopup(){
      this.resetForm();
      if(this.selectedLayer != undefined){
        this.addSelectedParcel(this.selectedLayer);
      }
      this.getGroupByCode({
        code: 'DIGITAL_AGENDA'
      }).then(response => {
          let _setting = response.items.find(obj => obj.code == 'USE_DATALLERS');
          this.company_use_datallers = false;
          if(_setting !== undefined){
            this.company_use_datallers = _setting.value == '1'
          }
          if(!this.company_use_datallers){
          this.isZilierWork = false;
          this.currentStep = this.selectedLayer == undefined ?  1 : 2;
          this.currentItem = this.selectedLayer == undefined ? 1 : 2;
      }
      });
    },
  },
  methods: {
    ...mapActions({
      getLayers: "layers/getLayers",
      getWorkMachines: "works-work-machines/getWorkMachines",
      createWorkMachine: "works-work-machines/createWorkMachine",
      getWorks: "works-manager/getWorks",
      getWorksHasWorkMachines: "works-has-work-machines/getWorksHasWorkMachines",
      createWorkType: "works-manager/createWork",
      createSeasonOnParcelHasCulture: "seasonOnParcelHasCulture/createSeasonOnParcelHasCulture",
      getGroupByCode: 'settings/getGroupByCode'
    }),
    test() {

    },

    onCameras(){},
    onCameraChange(){},

    toggleCamera() {
      if(this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
				audio: false,
				video: true,
        playsinline: true
			});

			navigator.mediaDevices
				.getUserMedia(constraints)
				.then(stream => {
          this.isLoading = false;
					this.$refs.camera.srcObject = stream;
				})
				.catch(error => {
          this.isLoading = false;
					alert("May the browser didn't support or there is some errors.");
				});
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

			tracks.forEach(track => {
				track.stop();
			});
    },

    takePhoto() {
      if(!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
    .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },



    toggleCamera() {
    if(this.isCameraOpen) {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.stopCameraStream();
    } else {
      this.isCameraOpen = true;
      this.createCameraElement();
    }
  },
  takePhoto() {
  this.isPhotoTaken = !this.isPhotoTaken;

  const context = this.$refs.canvas.getContext('2d');
  context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
},
    resetForm(){
      this.$v.manualWorkData.$reset();
      if(this.$refs.googlemap){
        this.$refs.googlemap.resetSelection();
      }
      this.currentWorkOption = null;
      this.currentItem = 0;
      this.manualWorkData.work_date = '';
      this.manualWorkData.workMachine_UUID = "";
      this.manualWorkData.workMachine_name = "";
      this.manualWorkData.workUtility_UUID = "";
      this.manualWorkData.workUtility_name = "";
      this.manualWorkData.workType_UUID = "";
      this.manualWorkData.workType_name = "";
      this.manualWorkData.zilierCount = null;
      this.manualWorkData.zilierSalary = null;
      this.manualWorkData.notes = '';
      this.selectedLayers = [];
      this.instantFilters.workMachine = "";
      this.instantFilters.workUtility = "";
      this.instantFilters.workType = "";
      this.instantFilters.parcel = "";
      this.calendar = {};
    },
    buttonCloseEvent() {
      this.$emit("hidden");
    },
    tabSelectionChanged(e) {
      this.currentItem = e.addedItems[0];
      this.currentStep = this.workSteps.indexOf(this.currentItem);
    },
    backButtonEvent() {
      if (this.currentStep > (this.selectedLayer == undefined ? 0 : 1)) {
        this.currentStep = this.currentStep - 1;
      }
    },
    showToastMessage(type, message) {
      this.$emit("showToastMessage", type, message);
    },
    removeSelectedParcel(layer) {
      this.selectedLayers.splice(this.selectedLayers.indexOf(layer.uuid), 1);
    },
    addSelectedParcel(layer) {
      this.season_uuid_local = layer.season_uuid;
      layer.surface = Number.parseFloat(layer.surface);
      this.selectedLayers.push(layer);
    },
    continueFromMap() {
      this.currentStep++;
    },
    saveAndAddProduct(){
      this.saveManualWork(true);
    },
    saveOnly(){
      this.saveManualWork(false);
    },
    saveManualWork(addProduct) {
      this.$v.manualWorkData.$touch();
      if (!this.$v.manualWorkData.$invalid) {
        let manualWorkForSave = {
          company_uuid: this.currentDepartment.company_uuid,
          department_uuid: this.currentDepartment.department_uuid,
          season_uuid: this.season_uuid_local,
          work_date: this.manualWorkData.work_date,
          machines: [],
          work: {
            uuid: this.manualWorkData.workType_UUID,
            name: this.manualWorkData.workType_name
          },
          parcels:[],
          is_manual: 1,
          worker_type: this.isZilierWork ? 'dataller' : 'machine',
          datallers_count: this.isZilierWork ? this.manualWorkData.zilierCount : 0,
          dataller_total_salary: this.isZilierWork ? this.manualWorkData.zilierSalary : 0,
          notes: this.manualWorkData.notes
        };
        if(this.isZilierWork == false){
          manualWorkForSave.machines.push(
            {
              uuid: this.manualWorkData.workMachine_UUID,
              name: this.manualWorkData.workMachine_name,
              autopropulsat: 1,
              equipment: 0
            }
          );
          if(this.manualWorkData.workUtility_UUID && this.manualWorkData.workUtility_name){
            manualWorkForSave.machines.push(
              {
                uuid: this.manualWorkData.workUtility_UUID,
                name: this.manualWorkData.workUtility_name,
                autopropulsat: 0,
                equipment: 1
              }
            );
          }
        }
        else{
          let dataller_machine = this.workMachines.find(obj => obj.name == "ZILIER_MACHINE" && obj.is_dataller == true);
          if(dataller_machine === undefined){
            manualWorkForSave.machines.push({
              name: 'ZILIER_MACHINE',
              autopropulsat: 0,
              equipment: 0,
              is_dataller: 1
            });
          }else{
            dataller_machine.autopropulsat = dataller_machine.autopropulsat ? 1 : 0;
            dataller_machine.equipment = dataller_machine.equipment ? 1 : 0;
            manualWorkForSave.machines.push(dataller_machine);
          }
        }
        this.selectedLayers.forEach((l) => {
            manualWorkForSave.parcels.push({uuid: l.parcel_uuid, surface: l.surface, season_uuid: l.season_uuid});
        });
        this.createSeasonOnParcelHasCulture(manualWorkForSave).then((response) => {
            this.$emit('hideAndRefresh', addProduct, response.data);
        });
      } else {

      }
    },
    addNewTractor(tractorName) {
      let newMachine = {
        department_uuid: this.currentDepartment.department_uuid,
        company_uuid: this.currentDepartment.company_uuid,
        name: tractorName,
        autopropulsat: true,
      };
      this.createWorkMachine(newMachine).then((response) => {
        this.workMachines.push(response.data);
        this.$refs.comboWorkMashines.updateWithNewItem(response.data);
      });
    },
    addNewUtilaj(utilajName) {
      let newUtilaj = {
        department_uuid: this.currentDepartment.department_uuid,
        company_uuid: this.currentDepartment.company_uuid,
        name: utilajName,
        autopropulsat: 0,
      };
      this.createWorkMachine(newUtilaj).then((response) => {
        this.workMachines.push(response.data);
        this.$refs.comboUtilaje.updateWithNewItem(response.data);
      });
    },
    addNewWorkType(workTypeName) {
      let newWorkType = {
        department_uuid: this.currentDepartment.department_uuid,
        company_uuid: this.currentDepartment.company_uuid,
        name: workTypeName,
      };
      this.createWorkType(newWorkType).then((response) => {
        this.workTypes.push(response.data);
        this.$refs.comboWorkTypes.updateWithNewItem(response.data);
      });
    },
    detailStepClick(step_code) {
      this.detail_step_code = step_code;
      this.currentStep = this.currentStep + 1;
    },
    parcelClick() {
      this.currentStep = this.currentStep + 1;
    },
    applyParcelChanges() {
      this.currentStep = this.currentStep - 1;
    },
    changeSelectedTractor(e){
      let machine = this.workMachines.find(w => w.uuid == e);
      this.manualWorkData.workMachine_name = machine ? machine.name : null;
      this.preselectWork(this.manualWorkData.workMachine_UUID);
    },
    preselectWork(machine_uuid){
      this.getWorksHasWorkMachines(
        {
          company_uuid: this.currentDepartment.company_uuid,
          department_uuid: this.currentDepartment.department_uuid,
          work_machines_uuid: machine_uuid,
        }
      ).then(response => {
        let machineSetting = response.find(obj => obj.work_machines_uuid == machine_uuid);
        if(machineSetting !== undefined){
          let selectedWork = this.workTypes.find(obj => obj.uuid == machineSetting.works_uuid);
          if(selectedWork !== undefined){
            this.manualWorkData.workType_UUID = selectedWork.uuid;
            this.manualWorkData.workType_name = selectedWork.name;
          }
        }
      });

    },
    changeSelectedUtility(e){
      let machine = this.workMachines.find(w => w.uuid == e);
      this.manualWorkData.workUtility_name = machine ? machine.name : null;
      this.preselectWork(this.manualWorkData.workUtility_UUID);
    },
    changeSelectedWorkType(e){
      let workType = this.workTypes.find(w => w.uuid == e);
      this.manualWorkData.workType_name = workType ? workType.name : null;
    },
    navigateToDetailAdd(){
      this.resetDetailAdd();
      this.currentStep = this.currentStep + 1;
    },
    saveTractor(){
      this.$v.newTractorName.$touch();
      if (!this.$v.newTractorName.$invalid) {
        let newMachine = {
          department_uuid: this.currentDepartment.department_uuid,
          company_uuid: this.currentDepartment.company_uuid,
          name: this.newTractorName,
          autopropulsat: true,
        };
        this.createWorkMachine(newMachine).then((response) => {
          this.workMachines.push(response.data);
          this.manualWorkData.workMachine_UUID = response.data.uuid;
          this.manualWorkData.workMachine_name = response.data.name;
          this.currentStep = this.currentStep - 2;
        });
      }
    },
    saveUtility(){
      this.$v.newUtilityName.$touch();
      if (!this.$v.newUtilityName.$invalid) {
        let newUtilaj = {
          department_uuid: this.currentDepartment.department_uuid,
          company_uuid: this.currentDepartment.company_uuid,
          name: this.newUtilityName,
          autopropulsat: 0,
        };
        this.createWorkMachine(newUtilaj).then((response) => {
          this.workMachines.push(response.data);
          this.manualWorkData.workUtility_UUID = response.data.uuid;
          this.manualWorkData.workUtility_name = response.data.name;
          this.currentStep = this.currentStep - 2;
        });
      }
    },
    saveWorkType(){
      this.$v.newWorkTypeName.$touch();
      if (!this.$v.newWorkTypeName.$invalid) {
        let newWorkType = {
        department_uuid: this.currentDepartment.department_uuid,
        company_uuid: this.currentDepartment.company_uuid,
        dataller: this.isZilierWork,
        name: this.newWorkTypeName,
        };
        this.createWorkType(newWorkType).then((response) => {
          this.workTypes.push(response.data);
          this.manualWorkData.workType_UUID = response.data.uuid;
          this.manualWorkData.workType_name = response.data.name;
          this.currentStep = this.currentStep - 2;
        });
      }
    },
    resetDetailAdd(){
      this.newTractorName = '';
      this.newUtilityName = '';
      this.newWorkTypeName = '';
      this.$v.$reset();
    }
  },
  mounted: function () {
    this.getWorkMachines({
      //department_uuid: this.currentDepartment.department_uuid,
      //company_uuid: this.currentDepartment.company_uuid,
      enablePagination: 0,
      limit: 9999
    }).then((response) => {
      this.workMachines = response.data;
    });
    this.getWorks({
      department_uuid: this.currentDepartment.department_uuid,
      company_uuid: this.currentDepartment.company_uuid,
    }).then((response) => {
      this.workTypes = response.data;
    });
  },
};
</script>


<style lang="scss">
@import "@/assets/scss/digitalAgenda/manualWorkDialog.scss";
</style>
