import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
  getBBCH({commit}, payload) {
    return api.fetch('/cultures/' + payload.culture_uuid + '/bbch-values', payload, commit,(response) => {

    });
  },

  getDiseases({commit}, payload) {
    return api.fetchFull('/fito-diseases', payload, commit,(response) => {

    });
  },

  createDiseases({commit}, payload) {
    return api.post('/fito-diseases', payload, commit,(response) => {
    });
  },

  saveScouting({commit}, payload) {
    return api.post('/scouting', payload, commit,(response) => {

    });
  },

  getScoutings({commit}, payload) {
    return api.fetchFull(endpoints.SCOUTINGS, payload, commit,(response) => {

    });
  },

  getScoutingByUUID({commit}, payload) {
    return api.fetch('/scouting/' + payload.scouting_uuid, payload, commit,(response) => {

    });
  },
  getFilteredScoutings({commit}, payload) {
    return api.fetch(endpoints.SCOUTINGS, payload, commit,(response) => {
      commit(types.SET_SCOUTINGS, response.data);
      commit("common/"+types.SET_PAGINATION, response.pagination, { root: true });
    });
  },

}

export default actions;
