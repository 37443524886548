import * as types from "../../mutation-types";

const mutations = {
  [types.SET_CULTURES]: (state, payload) => {
    state.cultures = payload;
  },
  [types.SET_CULTURE]: (state, payload) => {
    state.culture = payload;
  },
  [types.ADD_CULTURE_ITEM]: (state, payload) => {
    state.cultures.push(payload);
  },
  [types.UPDATE_CULTURE_ITEM]: (state, payload) => {
    state.cultures.splice(state.cultures.indexOf(
      state.cultures.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_CULTURE_ITEM]: (state, payload) => {
    state.cultures.splice(state.cultures.indexOf(
      state.cultures.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_CULTURE_FILTERS]: (state, payload) => {
    state.culture_filters = payload;
  },
  [types.SET_VARIETIES]: (state, payload) => {
    state.varieties = payload;
  },
  [types.SET_VARIETY]: (state, payload) => {
    state.variety = payload;
  },
}

export default mutations;
