<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('works.cultures.name')" id="culture-list">
      <agro-filter-container :new-object="newObject" :title='$t("common.create_new")'>
        <culture-filters
            :limit="limit"
            ref="filters"
            @onFiltersApplied="onFiltersApplied"
        />
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            @onSortBy="onSortBy"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import CultureFilters from "~/pages/works/cultures/partials/CultureFilters.vue";
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import UnitAndRoleLink from "~/pages/users/partials/UnitAndRoleLink.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'Cultures',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      limit: 25,
      filters: this.cultureFilters ? this.cultureFilters : {},
      items: this.cultureVarieties ? this.cultureVarieties : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    AgroGrid,
    CultureFilters,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      cultureFilters: 'works-cultures/cultureFilters',
      cultures: 'works-cultures/cultures',
      cultureVarieties: 'works-cultures/varieties',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    config() {
      return {
        permission: permissions.CULTURES,
        permission_type: permissionTypes.module,
        edit: {
          url: '/works/cultures/{uuid}/varieties/{vuuid}',
          callback: this.onItemEdited,
        },
        delete: {
          method: this.deleteObject,
          callback: this.onItemDeleted,
          disableIf: this.disableDeleteIf,
          disableMessage: this.$t('works.cultures.cannot_be_deleted')
        }
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        { name: this.$t('works.cultures.name'), col: 'cname', width: '25%', sortable: true },
        { name: this.$t('works.cultures.variety'), col: 'vname', width: '25%', sortable: true },
        { name: this.$t('unit_name'), col: 'department.name', align: 'center', width: '15%'},
        { name: this.$t('common.created_at'), col: 'created_at', align:'right', width: '15%', sortable: false },
        { name: this.$t('common.last_update'), col: 'updated_at', align:'right', width: '15%', sortable: false },
      ];
    },
  },
  methods: {
    ...mapActions({
        deleteObject: 'works-cultures/deleteCulture', //check to delete if has stock
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("works-cultures/"+type.SET_CULTURE_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: '/works/cultures/varieties/add'
      });
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      //check if has stock
      this.items.splice(
        this.items.indexOf(
          this.items.find(it => it.uuid === item.uuid)
        ), 1
      );
    },
    disableDeleteIf(row) {
      return !row.is_deletable;
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    onSortBy(data) {
      this.filters.sortBy = data.sortBy;
      this.filters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    },
    parentName(item) {
      if(item.parent) {
        return {
          title: item.parent.name,
          link: '/salary-calculator/sc-work/add?work_uuid='+item.uuid+'&work_name='+item.name,
        };
      }
    }
  },
  watch: {
    cultureVarieties: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
    cultureFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
