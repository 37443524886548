<template>
  <div class="import-attribute-list-item">
    <draggable :id="'import-attributes.' + item.id" :list="items" class="list-group" draggable=".item" :group="'a'"
      :move="checkMove" @change="draggableChanged">
      <div class="row"
        style="border: 1px solid #D3D3D3; margin-left: 1px; margin-right: 3px; padding-top: 10px; padding-bottom: 10px; align-content: center;">
        <div slot="header" class="col-lg-12" role="group" aria-label="Basic example" style="align-content: center;">
          <div class="list-label">{{ item.name }}</div>
        </div>
        <!-- <div class="col-lg-6" v-if="group_by_active">
          <b-form-select v-model="selected_group_by" class="form-control" style="margin: 0px;">
            <option v-for="option in group_by_options" :key="option.id" :value="option.value">
              {{ option.text }}
            </option>
          </b-form-select>
        </div> -->
      </div>
      <div class="placeholder" :class="{ 'on-move': onMove, invalid: !isValid }" v-if="!onMove && items.length === 0">
      </div>
      <div class="list-group-item item c-move import-item" v-for="element in items" :key="element.name">
        {{ element.name }}
      </div>
    </draggable>
    <img src="~@/assets/images/desktop/icons/controls/check-circle.svg" class="icon-svg" v-if="items.length > 0" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { splitGetValue } from "@/helpers/common";

export default {
  name: "ImportAttributeListItem",
  props: {
    data: {
      type: Object,
      default: {},
    },
    onMove: {
      type: Boolean | null,
      default: false,
    },
    groupBy: {
      type: Boolean | null,
      default: false,
    },
  },
  data() {
    return {
      on_move: this.onMove !== undefined ? this.onMove : false,
      item: this.data ? this.data : {},
      moveEvent: null,
      invalid: false,
      /*group_by_options: [
        { id: 1, value: 'group_by', text: this.$t("group_by") },
        { id: 3, value: 'sum', text: this.$t("sum") },
        { id: 4, value: 'max', text: this.$t("max") },
        { id: 5, value: 'min', text: this.$t("min") },
        { id: 6, value: 'average', text: this.$t("average") },
        { id: 7, value: 'count', text: this.$t("count") },
        { id: 8, value: 'concat', text: this.$t("concat") },
      ],
      selected_group_by: 'group_by',*/
      group_by_active: this.groupBy !== undefined ? this.groupBy : false,
    };
  },
  computed: {
    items() {
      return this.item.items ? this.item.items : [];
    },
    isValid() {
      return !this.invalid;
    },
  },
  methods: {
    draggableChanged(val) {
      this.item.event = val;

      if (this.moveEvent) {
        this.item.moveEvent = Object.assign(
          {},
          {
            from: splitGetValue(this.moveEvent.from.id, "."),
            to: splitGetValue(this.moveEvent.to.id, "."),
            originalTo: this.moveEvent.to.id,
            targetIndex:
              this.moveEvent && this.moveEvent.draggedContext
                ? this.moveEvent.draggedContext.futureIndex
                : null,
          }
        );
      }

      this.$emit("onDragChanged", this.item);
      this.moveEvent = null;
    },
    checkMove: function (evt) {
      this.moveEvent = evt;
      this.$emit("onMove", evt);
    },
    validate() {
      this.$set(this, "invalid", this.item.items.length <= 0);
      return this.isValid;
    },
  },
  components: {
    draggable,
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.item = newVal;
        this.item.validate = this.validate;
      },
      deep: true,
      immediate: true,
    },
    groupBy: function (newVal) {
      this.group_by_active = newVal;
    },
    onMove: function (newVal) {
      this.on_move = newVal;
    },
  },
};
</script>

<style scoped>
.import-attribute-list-item {
  position: relative;
}

.placeholder {
  height: 40px;
  background-color: #ededed;
}

.on-move {
  border-color: #000000 !important;
  background-color: #cccccc;
}

.success-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.list-label {
  font-weight: bold;
}

.import-item {
  border: 1px #ededed solid;
}

.invalid {
  background-color: #dc3545;
}

.icon-svg {
    position: absolute;
    right: 10px;
    top: 7px;
  }
</style>
