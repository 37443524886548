<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")'
               :class="{ 'field-error' : $v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('stock_management.products.barcode') }}</label>
        <input type="text" v-model="data.barcode"
               :placeholder="$t('stock_management.products.barcode')"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('stock_management.products.code') }}</label>
        <input v-model="data.code"
               class="form-control"
               placeholder=""/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <div class="row">
          <div class="form-group col-sm-12 col-lg-12 text-left">
            <label class="control-label">{{ $t('stock_management.products.type') }}</label>
            <vue-bootstrap-typeahead
              :data="product_types"
              v-model="product_type_search"
              ref="productType"
              :serializer="s => s.name"
              :placeholder="$t('stock_management.products.product_type_search')"
              @hit="onProductTypeSelected"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 col-lg-3 form-group">
        <label class="control-label">{{ $t('stock_management.products.select_mu') }}</label>
        <b-form-select
          v-model="data.measure_unit_uuid"
          :class="{ 'field-error' : $v.data.measure_unit_uuid.$error }"
          :placeholder="$t('stock_management.products.select_mu')"
          class="form-control"
          :options="measure_unit_list"></b-form-select>
      </div>
    </div>

    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  buildDefaultDropdownList,
  buildDropdownOptions, only_price
} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
  name: 'SMProductsDetails',
  components: {
    Forbidden,
    AgroActionButtons,
    VueBootstrapTypeahead
  },
  data() {
    return {
      data: {},
      product_types: [],
      selected_product_type: null,
      product_type_search: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      products: 'smProducts/smProducts',
      productTypes: 'smProductTypes/smProductTypes',
      measureUnits: 'measureUnits/measureUnits',
    }),
    name() {
      return this.$t('stock_management.products.title')
    },
    config() {
      return {
        permission: permissions.SM_PRODUCTS,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/stock-management/sm-products',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this) && this.data.is_editable;
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    measure_unit_list() {
      return buildDefaultDropdownList(Object.assign(this.measureUnits));
    },
  },
  methods: {
    ...mapActions({
      getProductTypes: 'smProductTypes/searchSmProductTypes',
      get: 'smProducts/getSmProduct',
      create: 'smProducts/createSmProduct',
      update: 'smProducts/updateSmProduct',
      delete: 'smProducts/deleteSmProduct',
      getMeasureUnits: 'measureUnits/searchMeasureUnits',
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm.data, 'vat_code', null);
      }, (data) => {
        vm.$refs.productType.handleInput(
          data.product_type ? data.product_type.name : ''
        );
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
    onlyPrice(event) {
      return only_price(event);
    },
    onProductTypeSelected(data) {
      this.data.product_type = {
        uuid: data.uuid,
        name: data.name,
      };
    },
  },
  mounted() {
    this.getMeasureUnits({
      enablePagination: 0,
    });
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    product_type_search: { //work type
      handler: function(newVal) {
        let vm = this;
        this.getProductTypes({
          name: newVal,
          pagination: 0,
        }).then((resp) => {
          vm.product_types = resp;
        });

        let productType = vm.product_types.find(c => c.name === newVal);
        if(productType) {
          this.$set(this.data, 'product_type', {
            uuid: productType.uuid,
            name: newVal,
          });
        } else {
          this.$set(this.data, 'product_type', {
            name: newVal,
          });
        }
        console.log('ptype search', this.data.product_type);
      },
      deep: true,
    },
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    'data.price_net': (data) => {

    },
    data: {
      handler: (data) => {

      },
      deep: true,
    },
  },
  validations(){
    return {
      data: {
        name: {
          required,
        },
        measure_unit_uuid: {
          required,
        },
      }
    }
  }
}
</script>

<style scoped>

</style>
