export const permissions = {
  COMPANY_MANAGEMENT: "COMPANY_MANAGEMENT",
  DEPARTMENTS: "DEPARTMENTS",
  USER_MANAGEMENT: "USER_MANAGEMENT",
  STOCK_MANAGEMENT: "STOCK_MANAGEMENT",
  INVOICING: "INVOICING",
  ROLES_PERMISSIONS: "ROLES_PERMISSIONS",
  UNIT_MANAGEMENT: "UNIT_MANAGEMENT",
  IMPORT: "IMPORT",
  CONFIGURATOR: "CONFIGURATOR",
  WORKS: "WORKS",
  PARCELS: "PARCELS",
  PERSONS: "PERSONS",
  WORK_MACHINES: "WORK_MACHINES",
  CULTURES: "CULTURES",
  SC_BONUS: "SC_BONUS",
  SC_HOLIDAY: "SC_HOLIDAY",
  SC_EMPLOYEE: "SC_EMPLOYEE",
  SC_EMPLOYEE_ROLE: "SC_EMPLOYEE_ROLE",
  SC_MEASURE_UNIT: "SC_MEASURE_UNIT",
  SC_SHIFT: "SC_SHIFT",
  SC_WORK: "SC_WORK",
  SC_WORK_GROUP: "SC_WORK_GROUP",
  SC_WORK_LOG: "SC_WORK_LOG",
  SC_REPORTS: "SC_REPORTS",
  SEASONS: "SEASONS",
  CF_IMPORT_CONFIG: "CF_IMPORT_CONFIG",
  SETTINGS: "SETTINGS",
  TRACTOR_IMPORT: "TRACTOR IMPORT",
  SHAPE_IMPORT: "SHAPE_IMPORT",
  FACE_CAM_EMAIL_IMPORT: "FACE_CAM_EMAIL_IMPORT",
  SALARY_CALCULATOR_IMPORT :"SALARY_CALCULATOR_IMPORT",
  SALARY_CALCULATOR_HECTARE_IMPORT :"SALARY_CALCULATOR_HECTARE_IMPORT",
  DATA_IMPORT: "DATA_IMPORT",
  WORK_TYPE_IMPORT: "WORK_TYPE_IMPORT",
  LEASE_CONTRACT: "LEASE_CONTRACT",
  SM_PARTNERS: "SM_PARTNERS",
  SM_PRODUCTS: "SM_PRODUCTS",
  SM_MOVEMENTS: "SM_MOVEMENTS",
  SM_STOCK: "SM_STOCK",
  SM_WAREHOUSES: "SM_WAREHOUSES",
  SM_TRANSACTIONS: "SM_TRANSACTIONS",
  STOCK_PARTNER_IMPORT: "STOCK_PARTNER_IMPORT",
  STOCK_PRODUCT_IMPORT: "STOCK_PRODUCT_IMPORT",
  STOCK_INITIAL_IMPORT: "STOCK_INITIAL_IMPORT",
  JUSTIFYING_DOCUMENTS: "JUSTIFYING_DOCUMENTS",
  SHARED_WORK_MACHINES: "SHARED_WORK_MACHINES",
  DIGITAL_AGENDA: "DIGITAL_AGENDA",
  DIGITAL_MAPS: "DIGITAL_MAPS",
  ARENDA: "ARENDA",
  SCOUTINGS: "SCOUTINGS",
  SGPS_DASC_IMPORT: "SGPS_DASC_IMPORT",
};

export const permissionTypes = {
  module: 'module',
  access: 'access'
}

export const actions = {
  r: 'r',
  w: 'w',
  x: 'x',
  R: 'r',
  W: 'w',
  X: 'X',
  READ: 'r',
  UPDATE: 'w',
  EDIT: 'w',
  DELETE: 'x'
};

export const defaultActions = {
  r: false,
  w: false,
  x: false
};

export function getPerm(perm, action) {
  return {
    type: perm,
    code: perm,
    action: action,
  }
}

//for grid items / actions edit-delete
export function hasPermission(object, user, type, action) {
  if (process.env.VUE_APP_DEBUG_PERMISSIONS === true) {
    console.log('has perm? ', object, 'user', user, 'type', type, 'action', action);
  }

  //object needs department_uuid, usually comes from grid rows
  if(user && (Boolean(user.is_superadmin === 1) || Boolean(user.is_company_admin === 1))) {
    return true;
  }

  let p = getAccess(object, user, type);
  console.log('check action', action, 'perm', p.access, 'object', object);
  if(p.access) {
    switch(action) {
      case 'r':
        return p.access.r || p.access.w || p.access.x;
      case 'w':
        return p.access.w;
      case 'x':
        return p.access.x;
      default:
        return false;
    }
  } else {
    return false;
  }
}

//for menu modules
export function hasModuleAccess(user, module) {
  //console.log('hasModuleAccess. user: ', user, 'module', module);
  if (user && (Boolean(user.is_superadmin === 1) || Boolean(user.is_company_admin === 1))) {
    return true;
  }
  //console.log('checking permissions. hasModuleAccess: ', 'user roles', user.roles);

  return Object.assign([], user?.roles).filter((role) => {
    return role.permissions.find(
      p => p.code.toString().toUpperCase() === module.toString().toUpperCase()
    );
  }).length > 0;
}

export function getAccess(object, user, type) {
  if(user && user.uuid && type) {
    let role = Object.assign([], user?.roles).find(r => r.department_uuid === object.department_uuid);

    if (role) {
      let perm = role.permissions.find(
        p => p.code.toString().toUpperCase() === type.toString().toUpperCase()
      );

      return perm ? perm : defaultActions;
    }
  }

  return defaultActions;
}

/* Check permission */
export function canEdit(context) {
  if (process.env.VUE_APP_DEBUG_PERMISSIONS === true) {
    console.log('can edit?');
  }

  if(context.config && context.config.permission) {
    if (context.config.permission_type === permissionTypes.module) {
      return hasModuleAccess(context.$store.getters['auth/user'], context.config.permission);
    } else {
      return hasPermission(context.data, context.$store.getters['auth/user'], context.config.permission, actions.EDIT);
    }
  }

  return true; //be able to edit, if permission is not set
}

export function canDelete(context) {
  if (process.env.VUE_APP_DEBUG_PERMISSIONS === true) {
    console.log('can delete?');
  }
  if(context.config && context.config.permission) {
    if (context.config.permission_type === permissionTypes.module) {
      return hasModuleAccess(context.$store.getters['auth/user'], context.config.permission);
    } else {
      return hasPermission(context.data, context.$store.getters['auth/user'], context.config.permission, actions.DELETE);
    }
  }

  return true; //be able to delete if permission is not set
}
