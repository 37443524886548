<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ title }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <partner-details-template
              ref="partnerDetailsTemplate"
              :data="modalData"></partner-details-template>
          </div>
        </div>
        <div class="modal-footer col-lg-11">
          <div class="form-group row justify-content-center">
            <div class="col-lg-4">
              <button class="btn btn-success" @click="finish()">{{$t("common.ok")}}</button>
            </div>
            <div class="col-lg-4">
              <button class="modal-default-button btn btn-secondary ml-3" @click="close()">{{$t("common.close")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import PartnerDetailsTemplate from "~/pages/stock-management/sm-partners/partials/PartnerDetailsTemplate.vue";

export default {
  name: 'CreatePartnerDialog',
  components: {PartnerDetailsTemplate},
  props: ['show', 'data'],
  data: function () {
    return {
      modalData: this.data,
    };
  },
  computed: {
    title() {
      if(this.data && this.data.action === 'edit') {
        return this.$t('stock_management.partners.edit');
      }
      return this.$t('stock_management.partners.create_new');
    }
  },
  methods: {
    close: function () {
      this.$emit('close');
    },
    finish () {
      let isValid = this.$refs['partnerDetailsTemplate'].validate();

      if(isValid) {
        this.$emit('onOK',this.modalData);
        this.close();
      }
    },

  },
  watch: {
    data: function(newVal) {
      this.modalData = newVal;
    }
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
</style>
