import * as types from "../../mutation-types";

const mutations = {
    [types.SET_INPUTS]: (state, payload) => {
        state.inputs = payload;
    },
    [types.SET_INPUT]: (state, payload) => {
        state.input = payload;
    },
    [types.SET_INPUT_FILTERS]: (state, payload) => {
      state.input_filters = payload;
    },

    // [types.DELETE_MEASURE_UNIT_ITEM]: (state, payload) => {
    //     state.measure_units.splice(state.measure_units.indexOf(
    //         state.measure_units.find(c => c.uuid === payload.uuid)
    //     ), 1);
    // },

}

export default mutations;
