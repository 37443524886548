<template>
  <div id="filters" class="filters p-3 col-lg-12">
    <div class="row">
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label">{{ $t('works.cultures.name')}}</label>
        <input v-model="filters.cname" class="form-control"/>
      </div>
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label">{{ $t('works.cultures.variety')}}</label>
        <input v-model="filters.vname" class="form-control"/>
      </div>
<!--      <div class="col-sm-12 col-lg pb-3 pb-lg-0">-->
<!--        <label class="control-label">{{ $t('works.cultures.parent')}}</label>-->
<!--        <input v-model="filters.variety" class="form-control"/>-->
<!--      </div>-->
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label d-block">{{ $t('common.date_from')}}</label>
        <date-picker2
          v-model="creation_date_from"
          :value-type="creation_date_from_input"
          format="DD/MM/YYYY"
          type="date"
          placeholder="dd/mm/yyyy"
          :input-class="['date-picker-style']"
          @change="onCreationDateFromChanged"
          ref="date_from"
        ></date-picker2>
      </div>
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label d-block">{{ $t('common.date_to')}}</label>
        <date-picker2
          v-model="creation_date_to"
          :value-type="creation_date_to_input"
          format="DD/MM/YYYY"
          type="date"
          placeholder="dd/mm/yyyy"
          :input-class="['date-picker-style']"
          @change="onCreationDateToChanged"
          ref="date_to"
        ></date-picker2>
      </div>
    </div>
    <div class="row pt-3  ">
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <agro-filter @onChange="onDynamicFiltersChanged"/>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-2 pt-0 pt-lg-4 mt-0 mt-2">
        <button class="btn btn-primary col-lg-12" @click="applyFiltersClick">{{$t("common.show")}}</button>
      </div>
      <div class="col-sm-12 col-lg-3 pt-0 pt-lg-4 mt-0 mt-2">
        <button class="btn btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as type from '../../../../store/mutation-types'
    import DatePicker2 from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import * as endpoints from '../../../../store/api/endpoints';
    import {buildDropdownOptions} from "~/helpers/common";
    import AgroFilter from "~/components/AgroFilter/Index.vue";

    export default {
        name: 'CultureFilters',
        data() {
            return {
                filters:  this.cultureFilters ? this.cultureFilters : {},
                creation_date_from: null,
                creation_date_from_input: null,
                creation_date_to: null,
                creation_date_to_input: null,
            }
        },
        components:  {
          AgroFilter,
            DatePicker2,
        },
        computed: {
            ...mapGetters({
                currentDepartment: 'auth/currentDepartment',
                cultureFilters: 'works-cultures/cultureFilters',
                departments: 'company/departments',
            }),
            departments_list() {
                return buildDropdownOptions(Object.assign([], this.departments), {
                    value: 'uuid',
                    text: 'name',
                });
            },
        },
        methods: {
            ...mapActions({
                getCultureVarieties: 'works-cultures/getCultureVarieties',
            }),
            applyFiltersClick() {
                this.$set(this.filters, 'page', 1);
                this.applyFilters();
            },
            applyFilters() {
                this.$emit('onFiltersApplied', this.filters);
                this.filters.url =  endpoints.CULTURE_VARIETIES;
                this.filters.department_uuid = this.filters.department_uuid ?? this.currentDepartment.department_uuid;
                //this.filters.is_variety = 0;
                this.getCultureVarieties(this.filters);
            },
            resetFilters() {
                this.filters = {
                    sort_by: '',
                    sortBy: '',
                    name: '',
                    variety: '',
                    creation_date_from: '',
                    creation_date_to: '',
                    page: 1,
                };

                this.creation_date_from = null;
                this.creation_date_from_input = null;
                this.creation_date_to = null;
                this.creation_date_to_input = null;

                this.$store.commit("works-cultures/"+type.SET_CULTURE_FILTERS, this.filters);
                this.applyFilters();
            },
            onCreationDateFromChanged(date) {
                this.filters.from = date;
            },
            onCreationDateToChanged(date) {
                this.filters.to = date;
            },
            onDynamicFiltersChanged(selectedUUID) {
              this.filters.department_uuid = selectedUUID;
            }
        },
        mounted(){
            this.resetFilters();
        },
        watch: {
            cultureFilters: {
                handler: function(newVal) {
                    this.filters = newVal;
                },
                deep: true,
            },
        }
    }
</script>
