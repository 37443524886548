<template>
    <div>
        <card title="Preturi"></card>
        <div class="content-body pt-4">
            <div class="row">
                <div class="col-lg-12">
                    <DxDataGrid 
                        :ref="productPriceGridRefKey"
                        :data-source="prodDataSurce"
                        :show-borders="false"
                        :remote-operations="gridRemoteOperations"
                        :element-attr="gridAttributes"
                    >
                        <DxColumn
                            data-field="product.uuid"
                            caption="Produs"
                        >
                            <DxLookup
                                :data-source="productsData"
                                value-expr="uuid"
                                display-expr="name"
                            />
                            <DxRequiredRule message="Produsul este obligatoriu!"/>
                        </DxColumn>
                        <DxColumn
                            data-field="year"
                            data-type="string"
                            caption="An"
                        >
                            <DxRequiredRule message="Anul este obligatoriu!"/>
                        </DxColumn>
                        <DxColumn
                            data-field="price"
                            data-type="string"
                            caption="Pret"
                        >
                            <DxRequiredRule message="Pretul este obligatoriu!"/>
                        </DxColumn>
                        <DxColumn
                            data-field="measure_unit.uuid"
                            caption="Unitate de masura"
                        >
                            <DxLookup
                                :data-source="measureUnitsData"
                                value-expr="uuid"
                                display-expr="name"
                            />
                            <DxRequiredRule message="Unitatea de măsură este obligatorie!"/>
                        </DxColumn>
                        <DxPaging :total="3500" :page-size="15"/>
                        <DxPager
                            :visible="true"
                            :allowed-page-sizes="pageSizes"
                            display-mode="compact"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                        />
                        <DxEditing
                            :allow-adding="true"
                            :allow-updating="true"
                            :allow-deleting="true"
                            :use-icons="true"
                            mode="row"
                        >
                            <DxTexts
                                confirm-delete-message="Sigur vreți să ștergeți prețul?"
                                confirm-delete-title="Confirmare ștergere"
                            />
                        </DxEditing>
                        <DxHeaderFilter :visible="false" />
                        <DxFilterRow :visible="true" apply-filter="auto"/>
                        <DxSelection mode="single" />
                    </DxDataGrid>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as endpoints from '../../../store/api/endpoints'
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule, DxHeaderFilter, DxFilterRow, DxSelection } from 'devextreme-vue/data-grid';
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import CustomStore from 'devextreme/data/custom_store';
import * as api from '../../../store/api/index'

export default {
    name: "ArendaProductPrices",
    middleware: ['auth', 'check-company-admin'],
    data(){
        return {
            gridAttributes:{
                class: 'admin-grid'
            },
            productPriceGridRefKey: 'product-price-grid',
            prodDataSurce: new CustomStore({
                key: 'uuid',
                load: this.loadPrices,
                insert: this.insert,
                update: this.update,
                remove: this.delete
            }),
            gridRemoteOperations: {
                paging: true,
                filtering : true,
                sorting: true
            },
            productsData: [],
            pageSizes: [15, 25, 100],
            measureUnitsData: []
        };
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        DxEditing,
        DxTexts,
        DxLookup,
        DxRequiredRule,
        DxHeaderFilter, 
        DxFilterRow,
        DxSelection,
        Forbidden
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            users: 'user/users',
        }),
        canEdit() {
            return canEdit(this);
        },
        productPriceGrid: function() {
            return this.$refs[productPriceGridRefKey].instance;
        },
    },
    methods: {
        ...mapActions({
            getProducts: 'leasecontracts/getProducts',
            createProductPrice: 'leasecontracts/createProductPrice',
            geMeasureUnits: 'leasecontracts/geMeasureUnits',
            getProductPriceByUUID: 'leasecontracts/getProductPriceByUUID',
            updateProductPrice: 'leasecontracts/updateProductPrice',
            deleteProductPrice: 'leasecontracts/deleteProductPrice',
        }),
        collectFilters(loadOptions, params){
            if(loadOptions.filter?.filterValue){
                if(loadOptions.filter[0] == "product.uuid"){
                    params.product_name = this.productsData.find(p => p.uuid == loadOptions.filter.filterValue).name;
                }
                if(loadOptions.filter[0] == "year"){
                    params.year = loadOptions.filter.filterValue;
                }
            }

            if(loadOptions.sort && loadOptions.sort.length > 0){
                params.sortBy = loadOptions.sort[0].selector,
                params.sortDirection = loadOptions.sort[0].desc ? "DESC" : "ASC";
                if(loadOptions.sort[0].selector == 'product.uuid'){
                    params.sortBy = 'product_name';
                }
            }

            return params;
        },
        async loadPrices(loadOptions){
            let pageLimit = loadOptions.take;
            let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
            
            let dataSource =  {
                data:[],
                totalCount:0
            };
            let payload = {
                page: pageNumber,
                limit: pageLimit,
                sortBy: 'createdAt',
                sortDirection: 'DESC'
            }
            payload = this.collectFilters(loadOptions, payload);
            await  api.fetchFull(endpoints.LEASE_CONTRACT_PRODUCT_PRICE, payload, null,(response) => {
                dataSource.data = response.data.data;
                dataSource.totalCount = response.data.pagination.total
            });

            return dataSource;
        },
        async insert(obj){
            obj.product_uuid = obj.product.uuid;
            obj.measure_unit_uuid = obj.measure_unit.uuid;
            await this.createProductPrice(obj);
        },
        async update(key, obj){
            let oldObj = {
                uuid: key,
                product_uuid: '',
                year: 0,
                price: 0,
                measure_unit_uuid: ''
            };
            await  this.getProductPriceByUUID({uuid: key}).then(async response => {
                oldObj.product_uuid = obj.product ? obj.product.uuid : response.data.product.uuid;
                oldObj.year = obj.year ? obj.year : response.data.year;
                oldObj.price = obj.price ? obj.price : response.data.price;
                oldObj.measure_unit_uuid = obj.measure_unit ? obj.measure_unit.uuid : response.data.measure_unit.uuid;
                await this.updateProductPrice(oldObj); 
            });
        },
        async delete(key){
            let obj = {uuid: key, product_price_uuid: key}
            await this.deleteProductPrice(obj);
        }
    },
    mounted(){
        this.getProducts({limit:9999}).then(response => {
             this.productsData = response.data;
        });
        this.geMeasureUnits().then(response => {
            this.measureUnitsData = response.data;
        });
    }
}

</script>

<style lang="scss">
    @import "@/assets/scss/components/dxAdminDatagrid.scss";
</style>