import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
    getContracts({commit}, payload) {
        return api.fetchFull(endpoints.LEASE_CONTRACTS, payload, commit,(response) => {
          return response;
        });
    },
    // Products
    getProducts({commit}, payload) {
      return api.fetchFull(endpoints.LEASE_CONTRACT_PRODUCTS, payload, commit,(response) => {
        return response;
      });
    },
    getProductByUUID({commit}, payload) {
      return api.fetchFull(endpoints.LEASE_CONTRACT_PRODUCTS + '/' + payload.product_uuid, payload, commit,(response) => {
        return response;
      });
    },
    createProduct({commit}, payload) {
      return api.post(endpoints.LEASE_CONTRACT_PRODUCTS, payload, commit,(response) => {
        return response;
      });
    },
    updateProduct({commit}, payload) {
      return api.put(endpoints.LEASE_CONTRACT_PRODUCTS + '/' + payload.uuid, payload, commit,(response) => {
        return response;
      });
    },
    deleteProduct({commit}, key) {
      return api.remove(endpoints.LEASE_CONTRACT_PRODUCTS + '/' + key, null, commit,(response) => {
        return response;
      });
    },

    //product prices
    getProductPrices({commit}, payload) {
      return api.fetchFull(endpoints.LEASE_CONTRACT_PRODUCT_PRICE, payload, commit,(response) => {
        return response;
      });
    },
    getProductPriceByUUID({commit}, payload) {
      return api.fetchFull(endpoints.LEASE_CONTRACT_PRODUCT_PRICE + '/' + payload.uuid, payload, commit,(response) => {
        return response;
      });
    },
    createProductPrice({commit}, payload) {
      return api.post(endpoints.LEASE_CONTRACT_PRODUCT_PRICE, payload, commit,(response) => {
        return response;
      });
    },
    updateProductPrice({commit}, payload) {
      return api.put(endpoints.LEASE_CONTRACT_PRODUCT_PRICE + '/' + payload.uuid, payload, commit,(response) => {
        return response;
      });
    },
    deleteProductPrice({commit}, payload) {
      return api.remove(endpoints.LEASE_CONTRACT_PRODUCT_PRICE + '/' + payload.uuid, payload, commit,(response) => {
        return response;
      });
    },

    //MU
    geMeasureUnits({commit}, payload) {
      return api.fetchFull(endpoints.LEASE_CONTRACT_MU, payload, commit,(response) => {
        return response;
      });
    },

    //tariff system CRUD
    createTariffSystem({commit}, payload) {
      return api.post(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM, payload, commit,(response) => {
        return response;
      });
    },
    updateTariffSystem({commit}, payload) {
      return api.put(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM + '/' + payload.uuid, payload, commit,(response) => {
        return response;
      });
    },
    deleteTariffSystem({commit}, payload) {
      return api.remove(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM + '/' + payload.uuid, payload, commit,(response) => {
        return response;
      });
    },

    //tariff system items CRUD
    getTariffSystemItemByUUID({commit}, payload) {
      return api.fetchFull(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM_ITEM + '/' + payload.uuid, payload, commit,(response) => {
        return response;
      });
    },
    createTariffSystemItem({commit}, payload) {
      return api.post(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM_ITEM, payload, commit,(response) => {
        return response;
      });
    },
    updateTariffSystemItem({commit}, payload) {
      return api.put(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM_ITEM + '/' + payload.uuid, payload, commit,(response) => {
        return response;
      });
    },
    deleteTariffSystemItem({commit}, payload) {
      return api.remove(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM_ITEM + '/' + payload.uuid, payload, commit,(response) => {
        return response;
      });
    },

    getOwnersForPayments({commit}, payload) {
      return api.fetchFull(endpoints.AREA_OWNERS_FOR_PAYMENT, payload, commit,(response) => {
        return response;
      });
    },
    cashPayment({commit}, payload) {
      return api.post(endpoints.LEASE_CONTRACT_CASH_PAYMENT, payload, commit,(response) => {
        return response;
      });
    },
    editPayment({commit}, payload) {
      return api.put(endpoints.LEASE_CONTRACT_PAYMENT_EDIT, payload, commit,(response) => {
        return response;
      });
    },
    cancelPayment({commit}, payload) {
      return api.remove("/lease-contract-payment/" + payload.payment_uuid, payload, commit,(response) => {
        return response;
      });
    },
    createPayment({commit}, payload) {
      return api.post("/lease-contract-payment", payload, commit,(response) => {
        return response;
      });
    },
    productPayment({commit}, payload) {
      return api.post(endpoints.LEASE_CONTRACT_PRODUCT_PAYMENT, payload, commit,(response) => {
        return response;
      });
    },
    getPayment({commit}, payload){
      return api.fetchFull(endpoints.LEASE_CONTRACT_PAYMENT + "/" + payload.contract_uuid + "/area-owner/" + payload.owner_uuid + "/year/" + payload.year_uuid, payload, commit,(response) => {
        return response;
      });
    },
    getPaymentsByBalance({commit}, payload){
      return api.fetchFull(endpoints.LC_PAYMENT + "/" + payload.balance_uuid, payload, commit,(response) => {
        return response;
      });
    }
}

export default actions;
