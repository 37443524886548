<template>
    <div class="bbch-wrapper">
        <div class="bbch-title">Stadiu BBCH</div>
        <div class="title-separator"></div>
        <div class="bbch-value-wrapper">
            <b-form-checkbox
                :disabled="selected_bbch_values.filter(obj => obj == bb_value.uuid).length == 0 && selected_bbch_values.length >= 2"
                class="bbch-checkbox-row"
                v-for="(bb_value, index) in (bbch_values)"
                :key="index"
                v-model="selected_bbch_values"
                :value="bb_value.uuid"
                @change="changeBBCHValues"
                >
                BBCH {{ bb_value.name }}
            </b-form-checkbox>
        </div>
        <toast-message ref="toastMessage"></toast-message>
    </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import ToastMessage from "../../../components/Mobile/toastMessage.vue"

export default {
    name : "SelectBBCH",
    data(){
        return {
            bbch_values: [],
            selected_bbch_values: [],
            min_bbch: null,
            max_bbch: null
        };
    },
    components: {
        ToastMessage
    },
    watch: {

    },
    methods: {
        ...mapActions({
            getBBCHValues: "scouting/getBBCH",
            getCultures: "works-cultures/getCultures",
        }),
        changeBBCHValues(selected){
            let bbch_1 = null;
            let bbch_2 = null;
            for (let index = 0; index < selected.length; index++) {
                for (let idx_b = 0; idx_b < this.bbch_values.length; idx_b++) {
                    if(this.bbch_values[idx_b].uuid == selected[index]){
                        if(bbch_1 == null){
                            bbch_1 = this.bbch_values[idx_b];
                        }
                        else{
                            bbch_2 = this.bbch_values[idx_b];
                        }
                    }

                }

            }
            this.min_bbch = null
            this.max_bbch = null
            if(bbch_1 != null && bbch_2 != null){
                if(bbch_1.rank < bbch_2.rank){
                    this.min_bbch = bbch_1;
                    this.max_bbch = bbch_2;
                }
                else{
                    this.min_bbch = bbch_2;
                    this.max_bbch = bbch_1;
                }
            }
            else{
                this.min_bbch = bbch_1;
            }
            this.$emit("setBBCH", this.min_bbch, this.max_bbch);
        },
        resetForm(){
            this.selected_bbch_values = [];
            this.min_bbch = null;
            this.max_bbch = null;
        },
        persistBBCHSelections(){

        }
    },
    mounted(){
        let cultureUUID = '';
        this.getCultures().then(response => {
            if(response && response.data && response.data.length > 0 && response.data.filter(c => c.name.toUpperCase().includes("CARTOF").length > 0 )){
                cultureUUID = response.data.filter(c => c.name.toUpperCase().includes("CARTOF"))[0].uuid;
                this.getBBCHValues({culture_uuid: cultureUUID, limit: 9999}).then((response) => {
                    this.bbch_values = response;
                });
            }
        })
    }
}

</script>

<style lang="scss">
  @import "@/assets/scss/scoutingMobile/addBBCH.scss";
</style>
