<template>
     <div class="stock_details_holder">
        <div class="stock_details_spacer"></div>
            <div v-for="stock in stockData.stock_list" :key="stock.warehouse_uuid" class="info_line" @click="warehouseClickOnSelectionMode(stockData, stock)" >
                <div v-if="false" class="info_label">Deposit:</div>
                <div class="info_value">{{stock.warehouse_name}}</div>
                <div class="info_quantity">{{stock.quantity}} {{stockData.measure_unit.slug}}</div>
                 <img class="info_image" src="@/assets/images/icons/right_arrow_black.svg" @click="productHistoryClick(stockData, stock)"/>
            </div>
     </div>
</template>

<script>



export default {
    name: "StockLocations",
    props: {
        stockData: [],
        selectionMode: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {

        };
    },
    methods:{
        warehouseClickOnSelectionMode(stock_data, warehouse){
            if(this.selectionMode){
                this.$emit('warehouseClickOnSelectionMode', stock_data, warehouse);
            }
        },
        productHistoryClick(product, warehouse){
            if(!this.selectionMode){
                this.$emit('openHistoryDialog', product, warehouse);
            }
        },

    }
}
</script>
