<template>
  <div class="agro-import-mapper">
    <div class="row" v-if="templateList">
      <div class="form-group col-sm-4">
        <label class="control-label">{{$t("tools.configurator.import_templates")}}</label>
        <b-form-select v-model="template_uuid" class="form-control"
                       :options="templateList" @change="onTemplateChanged"></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <h5 class="pb-3 font-weight-bold">{{ $t('tools.configurator.required_import_attributes') }} ({{ importAttributeList ? importAttributeList.length : 0 }})</h5>
        <import-attribute-list
          :on-move="onMove"
          :data="importAttributeList"
          @onDragChanged="onDragAttrListChanged"
          ref="import-attribute-list"
          :quick-filter="true"
          :unfilled-filter="true"
          :group-by-feature="config.group_by_feature"
        ></import-attribute-list>
      </div>

      <div class="col-lg-4">
        <h5 class="pb-3 font-weight-bold">{{ $t('tools.configurator.available_csv_attributes') }} ({{ csvAttributes ? csvAttributes.length : 0 }})</h5>
        <draggable-list
          class=""
          size="strech"
          v-if="csvAttributes"
          id="csv_attributes"
          :name="csvFilename"
          :data="csvAttributes"
          :dragListChanged="onDragCsvListChanged"
          :checkMove="checkMove"
          :quick-filter="true"
        ></draggable-list>
        <file-loader
          ref="fileLoader"
          ext=".csv,.xls,.xlsx,.txt,.zip"
          @onFileLoaded="onFileLoaded"
          v-else></file-loader>
      </div>
      <div class="col-lg-4">
        <calculation-formula
          :csv-attributes="originalCsvAttributes"
          :template="currentTemplate"
          v-if="calculationFormula"
          @onActiveFieldFormulaChanged="onActiveFieldFormulaChanged"
          @onInactiveFieldFormulaChanged="onInactiveFieldFormulaChanged">
        </calculation-formula>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 pt-3">
        <draggable-list
          id="additional_attributes"
          :name="additionalTitle"
          subtitle="Drop here your additional attributes"
          :data="additionalAttributes"
          :dragListChanged="onDragAdditionalListChanged"
          :checkMove="checkAdditionalMove"
          :quick-filter="true"
        ></draggable-list>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 mt-4 mb-4">
        <button class="btn btn-md btn-primary col-lg-3" style="margin-right: 15px;" @click="save" v-if="canEdit">{{$t("save")}}</button>
        <button class="btn btn-md btn-success col-lg-4" @click="saveAndRetry" v-if="canEdit && retry_import_id">
          {{$t("common.save_and_retry")}}
        </button>
        <button class="btn btn-md btn-danger col-lg-3"
                @click="confirmDelete"
                v-if="canDelete && template_uuid">
          {{$t("common.delete")}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ImportAttributeList from "@/components/AgroImportMapper/partials/ImportAttributeList/index";
import DraggableList from "@/components/AgroImportMapper/partials/ImportAttributeList/DraggableList";
import FileLoader from "@/components/FileLoader";
import * as endpoint from '@/store/api/endpoints'
import {
  buildDefaultDropdownList,
  errorMessage,
  getHtmlElementAttribute,
  infoMessage,
  successMessage,
  copyArray
} from "@/helpers/common";
import {mapActions, mapGetters} from "vuex";
import {eventBus} from "@/plugins/eventBus";
import {canEdit, canDelete} from '@/helpers/Permissions';
import CalculationFormula from "@/components/AgroImportMapper/partials/CalculationFormula";
import Cookies from "js-cookie";

export default {
  name: "AgroImportMapper",
  props: ['data', 'type', 'templates', 'calculationFormula'],
  components: {
    CalculationFormula,
    FileLoader,
    DraggableList,
    ImportAttributeList
  },
  data() {
    return {
      config: this.data ? this.data : {},
      onMove: false,
      csvAttributes: undefined,
      originalCsvAttributes: undefined,
      importAttributeList: this.requiredAttributes ? this.requiredAttributes : [],
      additionalAttributes: [],
      fileObject: null,
      template_uuid: null,
      templateList: undefined,
      importAttributeFilter: '',
      csvAttributeFilter: '',
      currentTemplate: undefined,
      retry_import_id: null,
      activeFieldFormula: null,
      inactiveFieldFormula: null,
      currentDepartment: null,
    };
  },
  computed: {
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
        return canDelete(this);
    },
    additionalTitle() {
      return (this.$t('tools.configurator.additional_attributes') +' (' + (this.additionalAttributes ? this.additionalAttributes.length : 0))+')';
    },
    fileUploadPath() {
        return endpoint.CSV_HEADERS;
    },
    csvFilename() {
      return this.fileObject ? this.fileObject.name : 'No file uploaded';
    },
  },
  methods: {
    ...mapActions({
        getRequiredAttributes: 'importConfig/getRequiredAttributes',
        getTemplates: 'importConfig/getTemplates',
        getTemplate: 'importConfig/getTemplate'
    }),
    onDragCsvListChanged(val) {
        this.onMove = false;
    },
    onDragAttrListChanged(val) {
        this.onMove = false;

        if(val.moveEvent && val.moveEvent.from && val.moveEvent.to) { //moved between the import attributes
          let sourceItem = this.importAttributeList.find(a => a.id === val.moveEvent.from);
          let targetItem = null;

          if(val.moveEvent && val.moveEvent.to === 'additional_attributes') {
            this.additionalAttributes.push(sourceItem.items[0]);
            sourceItem.moveEvent = null;
            sourceItem.items = [];
            return;
          } else {
            targetItem = this.importAttributeList.find(a => a.id === val.moveEvent.to);
          }

          if(targetItem && targetItem.items && targetItem.items.length > 0) {
            //target spot is already used, nothing to do
            return false;
          } else {
            //move to specific group
            if(targetItem) {
              targetItem.items = [sourceItem.items[0]];
              targetItem.moveEvent = null;
            } else {
              this.csvAttributes.splice((sourceItem.moveEvent.targetIndex ? sourceItem.moveEvent.targetIndex : 0), 0, sourceItem.items[0]); //put back to csv fields
            }

            sourceItem.items = []; //remove from source field
            sourceItem.moveEvent = null;
          }
        } else {
          if(val.event) {

            if(val.event.added && val.items && val.items.length > 0) {
              //remove
              return false;
            }

            if(val.event.removed && val.items && val.items.length > 0) {
              this.csvAttributes.splice((val.moveEvent.targetIndex ? val.moveEvent.targetIndex : 0), 0, val.items[0]); //insert to csv fields
              this.importAttributeList.find(a => a.id === val.id).items = []; //remove from import fields
            }
          }
        }

    },
    checkMove: function(evt){
      this.onMove = true;
      //stop adding more than 1 to import attributes
      let isAdditionalAttributes = getHtmlElementAttribute(evt.to, 'id') === 'additional_attributes';
      if(!isAdditionalAttributes && (evt.relatedContext.list && evt.relatedContext.list.length > 0)) {
          this.onMove = false;
          return false;
      }
    },
    onDragAdditionalListChanged(data) {
      //todo
    },
    checkAdditionalMove: function(evt){
      let isCsvAttributes = getHtmlElementAttribute(evt.to, 'id') === 'csv_attributes';
      if(!isCsvAttributes && evt.relatedContext.list && evt.relatedContext.list.length > 0) {
        this.onMove = false;
        return false;
      }
    },
    validate() {
      return this.$refs['import-attribute-list'].validate();
    },
    onFileLoaded(fileObject) {
      this.fileObject = fileObject;
      let vm = this;
      this.$store.dispatch('common/getCSVHeaders', {
        department_uuid: this.currentDepartment?.uuid,
        import_type: this.type,
        file: fileObject,
      }).then((resp) => {
        vm.csvAttributes = resp;
        vm.$set(vm, 'originalCsvAttributes', copyArray(resp));
      }).catch((e) => {
        console.log('onFileLoaded ex', e);
        if(vm.$refs['fileLoader']) {
          vm.$refs['fileLoader'].removeFile();
        }
      });
    },
    getCsvHeadersQuery(import_id) {
      this.retry_import_id = import_id;
      let vm = this;
      this.$store.dispatch('common/getCSVHeadersQuery', {
        department_uuid: this.currentDepartment?.uuid,
        import_type: this.type,
        import_id: import_id
      }).then((resp) => {
        let fileInfo = resp.data.data;
        vm.csvAttributes = fileInfo.items;
        vm.$set(vm, 'originalCsvAttributes', copyArray(fileInfo.items));
        vm.fileObject = { name: fileInfo.file_info.uploaded_file.original_filename };
      });
    },
    getCollection() {
        return {
            type: '',
            name: this.csvFilename,
            filename: this.csvFilename,
            import_attributes: this.importAttributeList,
            used_csv_attributes: this.csvAttributes,
            csv_attributes: this.originalCsvAttributes,
            additional_attributes: this.additionalAttributes,
            active_formula: this.activeFieldFormula,
            inactive_formula: this.inactiveFieldFormula,
        };
    },
    onTemplateChanged(value) {
      let vm = this;
      this.save_and_retry = null;

      if(value) {
        this.getTemplate({
          type: this.type,
          uuid: value
        }).then((resp) => {
          vm.currentTemplate = resp;
          vm.populateRequiredAttributes(resp.import_attributes);
          vm.populateCsvAttributes(resp.used_csv_attributes);
          vm.populateAdditionalAttributes(resp.additional_attributes);
          vm.$set(vm, 'originalCsvAttributes', copyArray(resp.csv_attributes));
        });
      } else {
          this.importAttributeList = this.importAttributeList.filter((attr) => {
            attr.items = [];
            return attr;
          });
          this.additionalAttributes = [];
          this.csvAttributes = undefined;
          this.originalCsvAttributes = [];
          this.fileObject = null;
          vm.currentTemplate = undefined;
      }

      if(this.config !== undefined) {
          this.$set(this.config, 'template_uuid', value);
      }

      eventBus.$emit("onTemplateChanged", value);
    },
    populateRequiredAttributes(list) {
      let originalAttributeList = Object.assign([], this.importAttributeList);
      this.importAttributeList = [];

      if(list) {
        this.importAttributeList = originalAttributeList.map((item) => {
          item.items = [];

          let attr = list.find(a => a.field === item.field);

          item.items.push({
            id: attr.field,
            uuid: attr.id,
            name: attr.name,
          });

          return item;
        });
      }
    },
    populateCsvAttributes(list) {
      if(!this.fileObject) {
        this.fileObject = { name: '' };
      }
      this.fileObject = Object.assign(this.fileObject, {});
      this.fileObject.name = (list && list.length > 0 ? list[0].filename :
        (this.currentTemplate ? this.currentTemplate.name : undefined) );
      this.csvAttributes = list.map((item) => {
          return {...item, ...{uuid: item.field}};
      });
    },
    populateAdditionalAttributes(list) {
      if(list) {
        this.additionalAttributes = list.map((item) => {
          return {...item, ...{
              uuid: item.field,
              name: item.value
            }};
        });
      } else {
        this.additionalAttributes = [];
      }
    },
    async init() {
      const dom = await this.$store.dispatch('department/getDepartments', {
        domain: Cookies.get('domain')
      });
      this.currentDepartment = dom ? dom[0] : '';

        let vm = this;
        this.getTemplates({
          type: this.type,
        }).then((templates) => {
          vm.templateList = buildDefaultDropdownList(
            templates,
            { value: 'uuid', text: 'name' },
            { value: null, text: '-'+this.$t('tools.configurator.new_template')+'-' });
        });

        this.getRequiredAttributes({
          type: this.type
        }).then((resp) => {
          vm.importAttributeList = resp;
        });
    },
    addTemplateAndSelect(tpl) {
        this.templateList.push(tpl);
        this.template_uuid = tpl.value;
        this.onTemplateChanged(tpl.value);
    },
    save() {
      return new Promise((resolve, reject) => {
        let vm = this;
        if(!this.config.create) return;

        if(this.validate()) {
          if (this.currentTemplate) {
            this.config.update({...this.getCollection(), ...{uuid: this.currentTemplate.uuid, type: this.data.id}})
              .then((resp) => {
                successMessage(vm, resp.message);
                resolve(resp);
              });
          } else {
            this.config.create({...this.getCollection(), ...{type: this.data.id}})
              .then((resp) => {
                successMessage(vm, resp.message);
                vm.addTemplateAndSelect({
                  value: resp.data.uuid,
                  text: resp.data.name
                });
                resolve(resp);
              }).catch((e) => {
              errorMessage(vm, e.message);
              reject(e);
            });
          }
        } else {
          errorMessage(this, this.$t('fill_out_error'));
          reject(false);
        }
      });
    },
    saveAndRetry() {
      let vm = this;

      if(this.retry_import_id) {
        this.save().then((resp) => {
          vm.$store.dispatch('common/retryCSVImport', {
            import_id: vm.retry_import_id,
          }).then((resp) => {
            setTimeout(function() {
              infoMessage(vm, vm.$t('tools.configurator.added_to_queue', { file: vm.fileObject.name }))
              vm.$router.push({
                path: '/tools/import'
              });
            }, 800);
          });
        });
      }
    },
    confirmDelete() {
      let vm = this;
      if(!this.canDelete) return;
      if(!this.currentTemplate) return;

      this.$dialog.confirm(this.$t('common.confirm_delete', { title: this.currentTemplate.name }))
        .then((dialog) => {
          vm.config.delete({
            uuid: vm.currentTemplate.uuid,
          }).then((response) => {
            dialog.close();
            successMessage(vm, response.message);
            //switch to new template
            vm.templateList.splice(
              vm.templateList.indexOf(
                vm.templateList.find(t => t.value === response.data.uuid)
              ), 1
            );
            vm.onTemplateChanged(undefined);
            vm.template_uuid = null;

          }).error((response) => {
            dialog.close();
            errorMessage(vm, response.message);
          });
        });
    },
    onActiveFieldFormulaChanged(formula) {
      this.activeFieldFormula = (Array.isArray(formula) && formula.length === 0 ? null : formula);
    },
    onInactiveFieldFormulaChanged(formula) {
      this.inactiveFieldFormula = (Array.isArray(formula) && formula.length === 0 ? null : formula);
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.config = newVal;
      },
      deep: true,
      immediate: true,
    },
    templates: {
      handler: function (newVal) {
        this.templateList = buildDefaultDropdownList(newVal);
      },
      deep: true,
      immediate: true,
    },
    csvAttributes: {
      handler: function(newVal) {},
      deep: true,
    },
    originalCsvAttributes: {
      handler: function(newVal) {},
      deep: true,
    }
  }
}
</script>
<style scoped>

</style>
