<template>
  <pdf-download-col :data="exported_data" v-if="exported_data"></pdf-download-col>
  <a v-on:click.prevent="onClick" class="text-secondary fs10" v-else> {{ name }} </a>
</template>

<script>
import {errorMessage} from "~/helpers/common";
import PdfDownloadCol from "~/pages/stock-management/sm-transactions/partials/Cols/PdfDownloadCol.vue";
export default {
  components: {PdfDownloadCol},
  props: ['data'], //transaction
  name: 'PdfExportCol',
  data() {
    return {
      transaction_filters: this.smTransactionFilters ? this.smTransactionFilters : {},
      exported_data: null,
    }
  },
  computed: {
    name() {
      return this.$t('stock_management.transactions.generate_pdf', { type: this.data.type_name });
    },
    transactionFilters() {
      return this.$store.getters['smTransactions/smTransactionFilters'];
    },
    transactionItems() {
      return this.$store.getters['smTransactions/smTransactions'];
    }
  },
  methods: {
    onClick() {
      let vm = this;

      this.$store.dispatch('smTransactions/getExportInfo', {
        type: this.data.transaction_type,
        url: this.data.export_url,
        response_type: 'application/json'
      }).then((resp) => {
        vm.exported_data = resp.data.transaction;
        this.$store.dispatch('smTransactions/downloadPdf', {
          type: resp.data.transaction_type,
          url: resp.data.download_url,
          filename: resp.data.download_filename,
          response_type: 'blob'
        });
      }).catch((e) => {
        console.log('check export error: ', e.response);
        if(e.response.status === 400 || e.response.status === 500) {
          if(e.response.data.errors && e.response.data.errors.error) {
            errorMessage(vm, e.response.data.errors.error);
          } else {
            errorMessage(vm, vm.$t('error_alert_text'));
          }
        }
      }); //after?
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
      },
      deep: true,
    },
    smTransactionFilters: {
      handler: function(newVal) {
          this.transaction_filters = newVal;
      },
      deep: true,
    },
  }
}

</script>
