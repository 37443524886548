<template>
    <div class="rent_details_holder">
        <div v-show="paymentMode == false">
            <div class="rent_details_spacer"></div>
            <div class="info_line">
                <div class="info_label">Nr. contract:</div>
                <div class="info_value">{{templateData.contract_number}}</div>
            </div>
            <div class="info_line">
                <div class="info_label">Parte contractată:</div>
                <div class="info_value">{{templateData.contract_items?.map(o => o.owner.name).join(', ')}}</div>
            </div>
            <div class="info_line">
                <div class="info_label">Suprafața totală:</div>
                <div class="info_value">{{ totalSurface }} ha</div>
            </div>
            <div class="info_line">
                <div class="info_label">Data începării:</div>
                <div class="info_value">{{templateData.start_date}}</div>
            </div>
            <div class="info_line">
                <div class="info_label">Data încheiării:</div>
                <div class="info_value">{{templateData.end_date}}</div>
            </div>
            <div class="info_line">
                <div class="info_label">Plata efectată:</div>
                <div class="info_image" v-show="templateData.status != 'paid'"> <img src="@/assets/images/icons/warning_yellow.svg"/></div>
                <div class="info_value">{{paymentText}}</div>
                <div class="info_expand" @click="expandPyamentDetails">
                    <img v-if="!expanded" src="@/assets/images/icons/arrow_down.svg"/>
                    <div v-if="expanded" class="collapse_button" >
                            <img src="@/assets/images/icons/arrow_up_green.svg"/>
                        </div>
                </div>
            </div>
        </div>
        <payment-details
            ref="paymentDetailsControl"
            v-show="expanded"
            :createSelectionMode="createSelectionMode"
            :paymentMode="paymentMode"
            :templateData="templateData"
            :editSelectionMode="editSelectionMode"
            :deleteSelectionMode="deleteSelectionMode"
            @handleParentSelection="handleParentSelection"/>
    </div>
</template>

<script>

import PaymentDetails from './paymentDetails.vue'

export default {
    name: 'ContractDetails',
    props: {
        paymentMode: {
            type: Boolean,
            default: false,
        },
        createSelectionMode: {
            type: Boolean,
            default: false,
        },
        templateData: {
            type: Object,
            default: {}
        },
        editSelectionMode: Boolean,
        deleteSelectionMode:Boolean
    },
    data(){
        return {
            totalSurface: 0, 
            expanded: false
        };
    },
    computed: {
        paymentText(){
            switch(this.templateData.status){
                case 'unpaid':
                    return 'Neplătit'
                break;
                case 'paid':
                    return 'Plătit'
                break;
                case 'partial_paid':
                    return 'Plătit pațial'
                break;
            }
        }
    },
    methods: {
        expandPyamentDetails(){
            this.expanded = !this.expanded;
        },
        selectAllPaymentYears(select){
            if(this.$refs.paymentDetailsControl){
                this.$refs.paymentDetailsControl.selectAllPaymentYears(select);
            }
        },
        handleParentSelection(hasAnySelected, idx_uuid, selected, year_uuid){
            this.$emit('handleParentSelection', hasAnySelected, idx_uuid, selected, year_uuid);
        },
        getSelectedPayments(){
            if(this.$refs.paymentDetailsControl ){
                return this.$refs.paymentDetailsControl.getSelectedPayments();
            }
        }
    },
    components: {
        PaymentDetails
    },
    mounted(){
        this.totalSurface = 0;
        if(this.templateData.contract_items){
            this.templateData.contract_items.forEach(item => {
                this.totalSurface = this.totalSurface + item.contracted_surface_absolute;
            });
        }
    }
}
</script>
