<template>
    <div class="rent_wrapper" v-if="canEdit || canDelete" v-cloak>
        <div class="content-body pt-4">
            <div class="row">
                <div class="col-lg-12">
                    <DxDataGrid 
                        :ref="contractGridRefKey"
                        :data-source="contractDataSurce"
                        :show-borders="false"
                        :remote-operations="gridRemoteOperations"
                        :element-attr="gridAttributes"
                    >
                        <DxColumn
                            data-field="contract_number"
                            data-type="string"
                            caption="Numar contract"
                        >
                            <DxStringLengthRule :min="1" message="Numele produsului este obligatoriu!"/>
                        </DxColumn>
                        <DxPaging :total="3500" :page-size="15"/>
                        <DxPager
                            :visible="true"
                            :allowed-page-sizes="pageSizes"
                            display-mode="compact"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                        />
                        <DxEditing

                            :allow-adding="false"
                            :allow-updating="false"
                            :allow-deleting="false"
                            :use-icons="true"
                            mode="row"
                        />
                        <DxHeaderFilter :visible="true" />
                        <DxFilterRow :visible="true" apply-filter="auto"/>
                        <DxSelection mode="single" />
                        <DxMasterDetail :enabled="true" template="masterDetailTemplate"/>
                        <template #masterDetailTemplate="{ data: contractData }">
                            <ContractItems :templateData="contractData" />
                        </template>
                    </DxDataGrid>
                </div>
            </div>
        </div>    
    </div>
    <div v-else>
      <forbidden></forbidden>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection, DxMasterDetail } from 'devextreme-vue/data-grid';
import Forbidden from "@/pages/errors/Forbidden.vue";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import CustomStore from 'devextreme/data/custom_store';
import ContractItems from './contractItems.vue';

export default {
    name: "ContractAdminList",
    middleware: ['auth', 'check-company-admin'],
    data(){
        return {
            gridAttributes:{
                class: 'admin-grid'
            },
            contractGridRefKey: 'contract-grid',
            gridRemoteOperations: {
                paging: true,
                filtering : true,
                sorting: true
            },
            pageSizes: [15, 25, 100],
            contractDataSurce: new CustomStore({
                key: 'uuid',
                load: this.loadContracts,
                insert: this.insert,
                update: this.update,
                remove: this.delete
            }),
        };
    },
    components:{
        DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection, DxMasterDetail,
        ContractItems
    },
    computed: {
        canEdit() {
            return canEdit(this);
        },
    },
    methods: {
        ...mapActions({
            getContracts: "leasecontracts/getContracts",
        }),
        async loadContracts(loadOptions){
            console.log('loadOptions -> ', loadOptions);
            let pageLimit = loadOptions.take;
            let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
            
            let dataSource =  {
                data:[],
                totalCount:0
            };
            let payload = {
                page: pageNumber,
                limit: pageLimit,
                sortBy: 'createdAt',
                sortDirection: 'DESC'
            }
            //payload = this.collectFilters(loadOptions, payload);
            await this.getContracts(null).then(response => {
                dataSource.data = response.data;
                dataSource.totalCount = response.pagination.total
            });
            // await  api.fetchFull(endpoints.LEASE_CONTRACT_PRODUCT_PRICE, payload, null,(response) => {
            //     dataSource.data = response.data.data;
            //     dataSource.totalCount = response.data.pagination.total
            // });

            return dataSource;
        },
        insert(obj){},
        update(key, obj){},
        delete(key){},

    }
}
</script>