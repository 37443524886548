<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('stock_management.movements.title')" id="stock-list">
    <span class="action_buttons">
      <div class="controls text-right pr-3">
        <img src="~@/assets/images/desktop/icons/controls/expand.svg" class="icon-svg collapse-icon d-inline-flex"
             :title='$t("toggle_filters")'
             data-toggle="collapse" data-target="#collapseFilters"
             aria-expanded="false" aria-controls="collapseFilters"
        />
        <stock-actions></stock-actions>
      </div>
    </span>
      <div class="row pb-4 collapse show" id="collapseFilters">
        <s-m-stock-movement-filters ref="filters"
                                    @onFiltersApplied="onFiltersApplied"
                                    @onFilterClickApplied="onFilterClickApplied"
        />
      </div>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            ref="grid"
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            :infinite-scroll="true"
            @onSortBy="onSortBy"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import SMStockMovementFilters from "~/pages/stock-management/sm-movements/partials/smStockMovementFilters.vue";
import StockActions from "~/pages/stock-management/sm-stocks/partials/StockActions.vue";
import {errorMessage} from "~/helpers/common";
import Forbidden from "~/pages/errors/Forbidden.vue";

export default {
  name: 'SMTransactions',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.stockMovementFilters ? this.stockMovementFilters : {},
      items: this.stock ? this.stock : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    Forbidden,
    StockActions,
    SMStockMovementFilters,
    AgroGrid,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      stockMovementFilters: 'smTransactions/smStockMovementFilters',
      stockMovements: 'smTransactions/smStockMovements',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    config() {
      return {
        permission: permissions.SM_MOVEMENTS,
        permission_type: permissionTypes.module,
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        { name: this.$t('stock_management.transactions.product'),
          col: 'product.name', subtitle: (item) => {
            return {
              title: item.product.barcode ?? '-',
              link: '/stock-management/sm-products/'+item.product.uuid
            }
          }, width: '17%', sortable: true, },
        {
          name: this.$t('stock_management.transactions.transaction_type'),
          col: 'transaction_type',
          width: '10%'
        },
        {
          name: this.$t('stock_management.transactions.movement_type'),
          col: 'type_lb',
          key: 'type',
          width: '5%'
        },
        { name: this.$t('stock_management.products.qty'),
          col: 'quantity_mu',
          key: 'quantity',
          width: '8%',
          align: 'center',
        },
        { name:
            this.$t('stock_management.products.old_qty'),
          col: 'old_quantity_mu',
          key: 'old_quantity',
          width: '7%',
          align: 'center',
        },
        { name: this.$t('stock_management.products.new_qty'),
          col: 'new_quantity_mu',
          key: 'new_quantity',
          width: '8%',
          align: 'center',
        },
        {
          name: this.$t('stock_management.transactions.source_whs'),
          col: 'source_warehouse.name',
          key: 'source_warehouse_uuid',
          suffix: '',
          width: '10%',
          align: 'center',
        },
        {
          name: this.$t('stock_management.transactions.dest_whs'),
          col: 'dest_warehouse.name',
          key: 'dest_warehouse_uuid',
          suffix: '',
          width: '8%',
          align: 'center',
        },
        { name: this.$t('stock_management.transactions.total'),
          col: 'total',
          suffix: this.$currency.name,
          align:'center',
          width: '10%'
        },
        { name: this.$t('common.created_at'), col: 'created_at', align:'right', width: '12%', sortable: true },
      ];
    },
  },
  methods: {
    ...mapActions({

    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("smTransactions/"+type.SET_SM_STOCK_MOVEMENT_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    onFilterClickApplied(filters) {
      this.items = [];
    },
    onSortBy(data) {
      this.stockMovementFilters.sortBy = data.sortBy;
      this.stockMovementFilters.sortDirection = data.sortDirection;
      this.$refs.filters.applyFiltersClick();
    }
  },
  watch: {
    stockMovements: {
      handler: function(newVal) {
        if(this.$refs['grid'].isInfiniteScrollEnabled) {
          newVal.map((item) => {
            this.items.push(item);
          })
        } else {
          this.items = newVal;
        }
        this.loading = false;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
    stockMovementFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  .controls {
    width: 300px;
  }
  .action_buttons {
    position: absolute;
    top: 10px;
    left: calc(100% - 300px);
  }
}
.dropdown {
  position: relative;
  top: -5px;
}
</style>
