<template>
  <div class="history-holder" v-if="canEdit">
    <card :title="$t('import_history') +
      ' (' +
      $t('tools.configurator.' + lineToUnderscore(import_ref)) +
      ')'
      " id="import-history">
      <file-list ref="importFileList" :data="uploaded_files_history" @importValidatedDataEvent="importValidatedData"></file-list>
      <div class="row d-flex justify-content-end" style="width: 250px">
        <button class="btn btn-md btn-secondary" @click="goBackToTypeList">
          {{ $t("back_to_list") }}
        </button>
      </div>
    </card>
    <ImportDepartmentSelector ref="importDepartmentSelector" :show="showDepartmentSelector"
      @upload="startValidatedDataImport" @cancel="cancelDepartmentSelector" :defaultOkCaption='$t("save")'
      :defaultTitleCaption='$t("tools.import.transfer_to_live_data")'></ImportDepartmentSelector>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import FileList from "../import/partials/FileList";
import ImportDepartmentSelector from "../import/partials/ImportDepartmentSelector";
import { mapActions, mapGetters } from "vuex";
import {
  canDelete,
  canEdit,
  permissions,
  permissionTypes,
} from "@/helpers/Permissions";
import Forbidden from "@/pages/errors/Forbidden";
import { getCalculateDateDaysAgo } from "~/helpers/dateHelper";
import {
  lineToUnderscore,
  successMessage,
  errorMessageList,
} from "~/helpers/common";
import Cookies from "js-cookie";

export default {
  name: "ImportHistory",
  data() {
    return {
      uploaded_files_history: this.recentlyUploadedFiles
        ? this.recentlyUploadedFiles
        : [],
      intervalId: null, // Store the interval ID here
      import_type: "",
      import_ref: "data-import",
      showDepartmentSelector: false,
    };
  },
  components: {
    FileList,
    Forbidden,
    ImportDepartmentSelector,
  },
  computed: {
    ...mapGetters({
      recentlyUploadedFiles: "company/recentlyUploadedFiles",
    }),
    config() {
      return {
        permission: permissions.CONFIGURATOR,
        permission_type: permissionTypes.module,
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
      getRecentlyUploadedFiles: "company/getRecentlyUploadedFiles",
      transferValidatedDataToLiveData: "importConfig/transferValidatedDataToLiveData",
    }),
    calculateDate30DaysAgo() {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - 30));
      // Format the date as a string in YYYY-MM-DD format if necessary
      // return pastDate.toISOString().split('T')[0];
    },
    getUploadedFiles(company) {
      if (company && company.uuid) {
        this.getRecentlyUploadedFiles({
          company_uuid: company.uuid,
          import_type: this.import_type,
          limit: 100,
          date_from: getCalculateDateDaysAgo(45),
        });
      }
    },
    cancelDepartmentSelector() {
      this.showDepartmentSelector = false;
      this.$refs.importFileList.setItemToLiveData(null);
    },
    startValidatedDataImport(transferData) {
      this.showDepartmentSelector = false;

      const transfer_settings = {
        import_id: transferData.import_id,
        import_type: transferData.import_type,
        department_uuid: transferData.department_uuid,
        warehouse_uuid: transferData.warehouse_uuid,
        season_uuid: transferData.season_uuid,
        source_data_type: transferData.source_data_type,
        uploaded_file_id: transferData.uploaded_file_id,
        session_id: transferData.session_id,
        filename: transferData.filename,
      };

      let vm = this;
      this.transferValidatedDataToLiveData(transfer_settings)
        .then((response) => {
          successMessage(vm, response.message);
          this.$refs.importFileList.updateProcessedAfterValidation(transferData.import_id, 1);
          this.$refs.importFileList.setItemToLiveData(null);
        })
        .catch((e) => {
          errorMessageList(vm, e.errors);
          this.$refs.importFileList.setItemToLiveData(null);
        });
    },
    importValidatedData(data) {
      data.show_import_type_selector = false;
      this.$refs.importDepartmentSelector.set(data);
      this.showDepartmentSelector = true;
    },
    lineToUnderscore(value) {
      return lineToUnderscore(value);
    },
    goBackToTypeList() {
      this.$router.back();
    },
  },
  async mounted() {
    if (this.$route.query.import_type_id) {
      this.import_type = this.$route.query.import_type_id;
      this.import_ref = this.$route.query.import_typ_ref;
    }

    let vm = this;
    const dom = await this.$store.dispatch("auth/checkDomain", {
      domain: Cookies.get("domain"),
    });
    this.company = dom;
    vm.getUploadedFiles(dom);
    this.intervalId = setInterval(function () {
      vm.getUploadedFiles(vm.company);
    }, 10000);
  },
  beforeDestroy() {
    // Use beforeUnmount() for Vue 3
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  watch: {
    recentlyUploadedFiles: {
      handler: function (newVal) {
        this.uploaded_files_history = newVal;
      },
      deep: true,
    },
  },
};
</script>
