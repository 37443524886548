<template>
  <div class="dynamic-department-filter-types" :class="[containerStyle, colDirection ?? 'col']">
    <div class="row">
      <div class="col-lg form-group">
        <img src="@/assets/images/icons/options.svg" class="c-pointer filter-icon" @click="showAgroFilterDialogUnits"/>
        <agro-filter-dialog :show="showAgroFilterDialog"
                                     :data="agroFilterDialogData"
                                     :singleSelect="singleSelect"
                                     @onOK="onDepSelectionApplied"
                                     @close="onClose"
                                     ref="dialog"
        ></agro-filter-dialog>
        <span class="c-pointer" @click="showAgroFilterDialogUnits">{{ $t('units_filter') }}</span>
        <div v-if="filters.department_uuid.length > 0" class="text-primary fs-small">
          {{ $t('item_selected', { count: filters.department_uuid.length}) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DepartmentList from "@/pages/my-company/departments/partials/DepartmentList.vue";
import AgroFilterDialog from "@/components/AgroFilter/AgroFilterDialog.vue";

export default {
  name: 'AgroFilter',
  props: ['containerStyle', 'colDirection', 'required', 'inputUUID', 'singleSelect'],
  data() {
    return {
      filters: {
        department_uuid: [],
      },
      showAgroFilterDialog: false,
      agroFilterDialogData: {},
    }
  },
  components: {
    AgroFilterDialog,
    DepartmentList,
  },
  computed: {
    ...mapGetters({
      domain: 'auth/domain',
      departments: 'department/departmentList',
      departmentFilterTypes: 'agroFilter/departmentFilterTypes',
    }),
  },
  methods: {
    ...mapActions({
      listDepartments: 'department/listDepartments',
      getDepartmentFilterTypes: 'agroFilter/getDepartmentFilterTypes'
    }),
    onClose() {
      this.showAgroFilterDialog = false;
    },
    onDepSelectionApplied(selectedDeps) {
      const pluck = Object.values(Object.assign([], selectedDeps).map((item) => {
        return item.uuid;
      }));
      this.$emit('onDepSelectionApplied', pluck.join());
      this.$emit('onChange', pluck.join());
      this.filters.department_uuid = pluck;
    },
    showAgroFilterDialogUnits() {
      //filters
      let vm = this;
        this.showAgroFilterDialog = true;
        this.agroFilterDialogData.departments = this.departments.filter((item) => {
          return vm.filters.department_uuid.indexOf(item.uuid) > -1;
        });
    }
  },
  mounted() {
    this.listDepartments({
      company_uuid: this.domain.uuid,
    });
  }
}
</script>
<style scoped>
.filter-icon {
  margin-right: 5px;
}
</style>
