<template>
  <a v-on:click.prevent="onClick">
  <img src="~@/assets/images/desktop/icons/controls/pdf.svg" class="icon-svg danger-filter"/>
  <div class="pl-3 fs10" > {{ data.type_name }} <br/>
    {{ data.document.document_header.current_model }}
  </div>
  </a>
</template>

<script>
import {errorMessage} from "~/helpers/common";

export default {
  props: ['data'], //transaction
  name: 'PdfDownloadCol',
  computed: {
    name() {
      return this.data.type_name;
    },
  },
  methods: {
    onClick() {
      let vm = this;
      this.$store.dispatch('smTransactions/getDownloadInfo', {
        type: this.data.transaction_type,
        url: this.data.export_url,
      }).then((resp) => {
        //check for errors
        this.$store.dispatch('smTransactions/downloadPdf', {
          type: this.data.document_uuid,
          url: this.data.download_url,
          filename: resp.data.download_filename,
          response_type: 'application/json'
        }).then((dl) => {
          //download
          this.$store.dispatch('smTransactions/downloadPdf', {
            type: this.data.document_uuid,
            url: this.data.download_url,
            filename: resp.data.download_filename,
            response_type: 'blob'
          })
        }).catch((e) => {
          console.log('preview download error: ', e.response);
          if(e.response.status === 400 || e.response.status === 500) {
            if(e.response.data.errors && e.response.data.errors.error) {
              errorMessage(vm, e.response.data.errors.error);
            } else {
              errorMessage(vm, vm.$t('error_alert_text'));
            }
          }
        });

      }).catch((e) => {
        console.log('check download error: ', e.response);
        if(e.response.status === 400 || e.response.status === 500) {
          if(e.response.data.errors && e.response.data.errors.error) {
            errorMessage(vm, e.response.data.errors.error);
          } else {
            errorMessage(vm, vm.$t('error_alert_text'));
          }
        }
      });
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
          console.log('download col data', newVal);
      },
      deep: true,
    }
  }
}

</script>
