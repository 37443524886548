<template>
     <DxTabPanel
        :element-attr="tabPanelAttributes"
        :data-source="receptionSteps"
        :loop="false"
        :selected-item="currentItem"
        :selected-index="currentStep"
        :animation-enabled="true"
        :swipe-enabled="false"
        :show-nav-buttons="false"
        @selection-changed="tabSelectionChanged($event)"
    >
        <template #item="{ data: step }">
            <div>
                <DxScrollView ref="receptionInputScroll" v-show="step == 1" class="reception-input-scroll" :use-native="true">
                    <b-button @click="currentStep = 1" v-if="false">Button xx</b-button>
                    <div class="reception_input_holder"
                        :class="[{input_holder_error: $v.receptionData.product.uuid.$error}]">
                        <div class="input_label">Produs</div>
                        <div><dx-combo-with-add ref="producComboWithAdd" @addNewItemInstant="addNewProductInstant" @selectionChanged="productSelected" addNewText="Produsul nu există" :comboDataset="products" placeholder="Alegeți produs"></dx-combo-with-add></div>
                    </div>
                    <div class="reception_input_holder"
                        v-show="showMeasureUnitForProduct"
                        :class="[{input_holder_error: $v.receptionData.product.mu_uuid.$error}]">
                        <div class="input_label">Unitate de măsură</div>
                        <div class="input_value" >
                            <DxSelectBox
                                v-model="receptionData.product.mu_uuid"
                                :search-enabled="true"
                                search-mode="contains"
                                search-expr="name"
                                class="input_value dx_input_value"
                                placeholder="Alegeți unitate de măsură"
                                :data-source="measureUnits"
                                display-expr="name"
                                value-expr="uuid"
                                :show-clear-button="false"
                                drop-down-button-template="imageIcon"
                                @selection-changed="muChanged"
                                >
                                    <template #imageIcon>
                                    <div>
                                        <div class="dx_combo_open_button" v-show="!receptionData.product.mu_uuid || receptionData.product.mu_uuid == ''">
                                            <img src="@/assets/images/icons/plus_green.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                        <div class="dx_combo_open_button" v-show="receptionData.product.mu_uuid && receptionData.product.mu_uuid.length > 0">
                                            <img src="@/assets/images/icons/edit.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                    </div>
                                </template>
                                <template #item="{data}">
                                    <div class="dx_custom_combo_item">{{data.name}}</div>
                                </template>
                            </DxSelectBox>
                        </div>
                    </div>
                    <div class="reception_input_holder"
                        :class="[{input_holder_error: $v.receptionData.partner.uuid.$error}]">
                        <div class="input_label">Furnizor</div>
                        <div><dx-combo-with-add ref="partnerComboWithAdd" @addNewItemInstant="addNewPartnerInstant" @selectionChanged="partnerSelected" addNewText="Furnizorul nu există" :comboDataset="partners" placeholder="Alegeți furnizor"></dx-combo-with-add></div>
                    </div>
                    <div class="reception_input_holder"
                        v-show="showCUIForPartner"
                        :class="[{input_holder_error: $v.receptionData.partner.cui.$error}]">
                        <div class="input_label">Cod unic de înregistrare</div>
                        <div class="input_value" >
                            <DxTextBox placeholder="Introduceți CUI" v-model="receptionData.partner.cui" class="global_dx_number_input"/>
                        </div>
                    </div>
                    <div class="reception_input_holder"
                        :class="[{input_holder_error: $v.receptionData.quantity.$error}]">
                        <div class="input_label">Cantitate</div>
                        <div class="input_value">
                            <DxNumberBox
                                placeholder="Introduceți cantitatea"
                                :min="0"
                                :format="'#0.## ' + selected_mu_symbol"
                                v-model="receptionData.quantity"
                                class="global_dx_number_input"
                                :show-clear-button="false"
                            /></div>
                    </div>
                    <div class="reception_input_holder"
                        :class="[{input_holder_error: $v.receptionData.unitPrice.$error}]">
                        <div class="input_label">Preț unitar net</div>
                        <div class="input_value">
                            <DxNumberBox
                                :min="0"
                                placeholder="Introduceți prețul unitar net"
                                format="#,##0.00 RON"
                                v-model="receptionData.unitPrice"
                                class="global_dx_number_input"
                                :show-clear-button="false"
                            /></div>
                    </div>
                    <div class="reception_input_holder"
                        :class="[{input_holder_error: $v.receptionData.warehouse.uuid.$error}]">
                        <div class="input_label">Depozit</div>
                        <div class="input_value">
                            <DxSelectBox
                                v-model="receptionData.warehouse.uuid"
                                :search-enabled="false"
                                search-mode="contains"
                                search-expr="name"
                                class="input_value dx_input_value"
                                placeholder="Alegeți depozit"
                                :data-source="warehouses"
                                display-expr="name"
                                value-expr="uuid"
                                :show-clear-button="false"
                                drop-down-button-template="imageIcon"
                                >
                                    <template #imageIcon>
                                    <div>
                                        <div class="dx_combo_open_button" v-show="!receptionData.warehouse.uuid || receptionData.warehouse.uuid == ''">
                                            <img src="@/assets/images/icons/plus_green.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                        <div class="dx_combo_open_button" v-show="receptionData.warehouse.uuid && receptionData.warehouse.uuid.length > 0">
                                            <img src="@/assets/images/icons/edit.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                    </div>
                                </template>
                                <template #item="{data}">
                                    <div class="dx_custom_combo_item">{{data.name}}</div>
                                </template>
                                </DxSelectBox>
                        </div>
                    </div>
                     <div class="reception_input_holder">
                        <div class="input_label">Observații</div>
                        <div class="input_value">
                             <DxTextArea v-model="receptionData.notes" class="global_dx_number_input" />
                        </div>
                     </div>
                    <div class="input-bottom-spacer"></div>
                </DxScrollView>
                <div v-if="false" v-show="step == 2" class="scanner_area">
                    <div>Funcția este în curs de dezvoltare!</div><br>
                    <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader><br>
                    <div>Codul scanat: </div><b></b>
                    <div>{{scannedCode}}</div>
                </div>
                 <div class="reception_bottom_sticky_two_line" v-show="step == 1 && showSaveButtons">
                    <div class="sticky_action_button" @click="saveReception(true)">Salvează și recepție nouă</div>
                    <div class="sticky_action_button_second" @click="saveReception(false)">Salvează</div>
                </div>
                <toast-message ref="toastMessage"></toast-message>
            </div>
        </template>

     </DxTabPanel>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import DxTabPanel from "devextreme-vue/tab-panel";
import { DetailCodes, InputDetailCodes } from "./reception.js"
import { required, helpers } from "vuelidate/src/validators";
import DxNumberBox from "devextreme-vue/number-box";
import DxTextBox from 'devextreme-vue/text-box';
import DxComboWithAdd from "@/components/Mobile/DxComboWithAdd/index.vue";
import { v4 } from 'uuid';
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxSelectBox from "devextreme-vue/select-box";
import ToastMessage from "@/components/Mobile/toastMessage.vue"
import DxTextArea from 'devextreme-vue/text-area';
import { StreamBarcodeReader } from "vue-barcode-reader";


const greaterThanZero = (value) => {
   return helpers.req(value) && value > 0;
};

const greaterOrEqualsThanZero = (value) => {
   return helpers.req(value) && value >= 0;
};

const requiredByParam = (param) => (value) => {
  if(param){
    return helpers.req(value) && value.length > 0;
  }
  return true;
};

export default {
    name: "Reception",
    data(){
        return {
            receptionSteps: [1,2],
            currentStep: 0,
            currentItem: 1,
            tabPanelAttributes: {
                class: 'reception_tabs'
            },
            detailStepCode: '',
            detailInputStepCode: '',
            showMeasureUnitForProduct: false,
            showCUIForPartner: false,
            newPartner: {
                uuid: '',
                name: '',
                cui: '',
                partner_type: ''
            },
            receptionData: {
                product: {
                    uuid: '',
                    name: '',
                    mu_uuid: ''
                },
                partner: {
                    uuid: '',
                    partner_type: '',
                    name: '',
                    cui: '',
                },
                quantity: null,
                unitPrice: null,
                warehouse: {
                    uuid: '',
                    name: ''
                },
                notes: ''
            },
            products: [],
            partners: [],
            warehouses: [],
            measureUnits: [],
            selected_mu_symbol: '',
            scannedCode: null
        }
    },
    computed: {
         ...mapGetters({
            currentDepartment: "auth/currentDepartment"
        }),
        showSaveButtons(){
            return (this.receptionData.product.uuid && this.receptionData.product.uuid.length > 0) ||
            (this.receptionData.partner.uuid && this.receptionData.partner.uuid.length > 0) ||
            (this.receptionData.quantity && this.receptionData.quantity > 0) ||
            (this.receptionData.unitPrice && this.receptionData.unitPrice > 0) ||
            (this.receptionData.warehouse.uuid && this.receptionData.warehouse.uuid.length > 0) ||
            (this.receptionData.notes && this.receptionData.notes.length > 0);
        },
        detailCodes(){
            return DetailCodes;
        },
        inputDetailCodes(){
            return InputDetailCodes;
        },

    },
    validations() {
        return {
            receptionData:{
                product:{
                    uuid: { required },
                    mu_uuid: {requiredByParam: requiredByParam(this.showMeasureUnitForProduct)}
                },
                partner: {
                    uuid: { required },
                    cui: {requiredByParam: requiredByParam(this.showCUIForPartner)}
                },
                quantity: {greaterThanZero},
                unitPrice: {greaterOrEqualsThanZero},
                warehouse: {
                    uuid: { required }
                },
            }
        };
    },
    components: {
        DxTabPanel, DxNumberBox,
        DxTextBox,
        DxComboWithAdd,
        DxScrollView,
        DxSelectBox,
        ToastMessage,
        DxTextArea,
        StreamBarcodeReader
    },
    methods: {
        ...mapActions({
            getPartners: 'smPartners/getSmPartners',
            getProducts: 'smProducts/getSmProducts',
            getProductTypes: 'smProductTypes/searchSmProductTypes',
            getPartnerTypes: 'smPartners/getSmPartnerTypes',
            getWarehouses: 'smWarehouses/getSmWarehouses',
            getMeasureUnits: 'measureUnits/getMeasureUnits',
            createSmProduct: 'smProducts/createSmProduct',
            createReception: 'smTransactions/createReceptionTransaction'
        }),

        onLoaded(){},
        onDecode(result){
            this.scannedCode = result;
            console.log('barcode result ', result);
        },
        tabSelectionChanged(e) {
          this.currentItem = e.addedItems[0];
          this.currentStep = this.receptionSteps.indexOf(this.currentItem);
          this.changeAddButtonVisibility();
        },
        goBack(){
            if(this.currentStep > 0){
                this.currentStep = this.currentStep - 1;
                this.setTitle();
            }
            else{
                this.$emit('goBackParent');
            }
        },
        setTitle(){
            if(this.currentStep == 0) {
                this.$emit('setTitle', 'Recepție produs', true);
            }
        },
        changeAddButtonVisibility(){
            //this.$emit('changeAddButtonVisibility', this.currentStep == 1 && this.detailStepCode != this.detailCodes.Warehouse);
            this.$emit('changeAddButtonVisibility', false);
        },
        saveReception(saveAndNew){
            let saveWithNewPartner = false;
            this.$v.receptionData.$touch();
            if (!this.$v.receptionData.$invalid) {
                let receptionForSave = {
                    department_uuid: this.currentDepartment.department_uuid,
                    warehouse_uuid: this.receptionData.warehouse.uuid,
                    notes: this.receptionData.notes,
                    product: {}
                };
                let productForSave = {
                    uuid: '',
                    barcode: null,
                    name: this.receptionData.product.name,
                    measure_unit_uuid:  this.receptionData.product.mu_uuid,
                    quantity: this.receptionData.quantity,
                    price_net: this.receptionData.unitPrice,
                    partner: {},
                    notes: this.receptionData.notes
                };
                if(!this.receptionData.product.uuid.startsWith('NEW_')){
                    productForSave.uuid = this.receptionData.product.uuid;
                }

                let partnerForSave = {
                    uuid: '',
                    partner_type: 'SRL',
                    name: this.receptionData.partner.name,
                    id_number: this.receptionData.partner.cui,
                };
                if(!this.receptionData.partner.uuid.startsWith('NEW_')){
                    partnerForSave.uuid = this.receptionData.partner.uuid;
                    partnerForSave.partner_type = this.receptionData.partner.partner_type;
                }
                else{
                    saveWithNewPartner = true;
                }
                productForSave.partner = partnerForSave;
                receptionForSave.product = productForSave;
                this.createReception(receptionForSave).then(response => {
                    this.$refs.toastMessage.showSuccess('Recepția a fost salvată cu succes!');
                    if(saveAndNew){
                        if(saveWithNewPartner){

                            this.partners.forEach(prt => {
                                if(prt.uuid == this.receptionData.partner.uuid){
                                    prt.uuid = response.data.product.partner.uuid;
                                    prt.name = response.data.product.partner.name;
                                    prt.id_number = response.data.product.partner.id_number;
                                    prt.partner_type = response.data.product.partner.partner_type;

                                    this.$refs.partnerComboWithAdd.updateWithNewItem(prt);

                                }
                            });
                        }

                        this.resetForm();
                    }
                    else{
                        this.closeTransactions('Recepția a fost salvată cu succes!');
                    }
                }).catch((e) => {
                    console.log('reception error ', e)
                    this.$refs.toastMessage.showError('A apărut o erroare la salvarea recepției. Contactați administratorul de sistem!');
                });
            }
            else{
                this.$refs.toastMessage.showError(this.createErrormMssages());
            }
        },
        createErrormMssages(){
            let errorMessages = [];

            if(this.$v.receptionData.product.uuid.$invalid){
                errorMessages.push("Produsul este obligatoriu!");
            }
            if(this.$v.receptionData.product.mu_uuid.$invalid){
                errorMessages.push("Unitatea de măsură este obligatorie!");
            }
            if(this.$v.receptionData.partner.uuid.$invalid){
                errorMessages.push("Furnizorul este obligatoriu!");
            }
            if(this.$v.receptionData.partner.cui.$invalid){
                errorMessages.push("Codul unic de înregisrare este obligatoriu!");
            }
            if(this.$v.receptionData.quantity.$invalid){
                errorMessages.push("Cantitatea trebuie să fie mai mare de 0!");
            }
            if(this.$v.receptionData.unitPrice.$invalid){
                errorMessages.push("Prețul unitar trebuie să fie egal sau mai mare de 0!");
            }
            if(this.$v.receptionData.warehouse.uuid.$invalid){
                errorMessages.push("Depozitul este obligatoriu!");
            }
            return errorMessages;
        },
        goForward(){
            if(this.currentStep < 1){
                this.currentStep = this.currentStep + 1;
                this.setTitle();
            }
        },
        addNewProductInstant(productName){
            let newProd = {uuid:"NEW_" + v4(), name: productName};
            this.products.push(newProd);
            this.$refs.producComboWithAdd.updateWithNewItem(newProd);
        },
        addNewPartnerInstant(partnerName){
            let _newPartner = {uuid:"NEW_" + v4(), name: partnerName};
            this.partners.push(_newPartner);
            this.$refs.partnerComboWithAdd.updateWithNewItem(_newPartner);
        },
        productSelected(selectedProduct){
            this.$refs['receptionInputScroll'].instance.release(false);
            if(selectedProduct && selectedProduct.length > 0 && selectedProduct[0]){
                this.showMeasureUnitForProduct = selectedProduct[0].uuid.startsWith('NEW_');
                this.receptionData.product.uuid = selectedProduct[0].uuid;
                this.receptionData.product.name = selectedProduct[0].name;
                if(selectedProduct[0].measure_unit){
                    this.selected_mu_symbol = selectedProduct[0].measure_unit.slug;
                    this.receptionData.product.mu_uuid = selectedProduct[0].measure_unit.uuid;
                }
            }
            this.$refs['receptionInputScroll'].instance.update();
            this.$refs['receptionInputScroll'].instance.release(true);
        },
        partnerSelected(selectedPartner){
            if(selectedPartner && selectedPartner.length > 0 && selectedPartner[0]){
                this.showCUIForPartner = selectedPartner[0].uuid.startsWith('NEW_');
                this.receptionData.partner.uuid = selectedPartner[0].uuid;
                this.receptionData.partner.name = selectedPartner[0].name;
                this.receptionData.partner.cui = selectedPartner[0].id_number;
                this.receptionData.partner.partner_type = selectedPartner[0].partner_type;
            }
        },
        muChanged(e){
            if(e.selectedItem){
                this.selected_mu_symbol = e.selectedItem.slug;
            }
            else{
                this.selected_mu_symbol = '';
            }
        },
        resetForm(){
            this.$v.$reset();
            this.$refs.producComboWithAdd.resetControl();
            //this.$refs.partnerComboWithAdd.resetControl();
            this.showCUIForPartner = false;
            this.receptionData.product.uuid = '';
            this.receptionData.product.name = '';
            this.receptionData.product.mu_uuid = '';

            this.receptionData.quantity = null;
            this.receptionData.unitPrice = null;
            this.receptionData.notes = '';
            this.receptionData.warehouse.uuid = '';
            this.receptionData.warehouse.name = '';

            this.selectWarehouse();
            this.$refs['receptionInputScroll'].instance.scrollTo(0)
        },
        selectWarehouse(){
            if(this.warehouses.length == 1){
                this.receptionData.warehouse.uuid = this.warehouses[0].uuid;
                this.receptionData.warehouse.name = this.warehouses[0].name;
            }
        },
        closeTransactions(message){
            this.$emit('closeTransactions', message);
        }
    },
    mounted(){
        this.setTitle();
        this.getWarehouses({}).then(response => {
            this.warehouses = response.data;
            this.selectWarehouse();
        });
        this.getProducts({enablePagination: 0, limit: 999999}).then(response => {
            this.products = response.data;

        });
        this.getPartners({enablePagination: false}).then(response => {
            this.partners = response.data;
        });
        this.getMeasureUnits().then(response => {
            this.measureUnits = response.data;
        });
    }

}
</script>

<style lang="scss">
  @import "@/assets/scss/stockMobile/reception.scss";
</style>
