<template>
    <DxDropDownBox
      ref="dropDownBoxRef"
      :drop-down-options="dropDownOptions"
      :input-attr="{ 'aria-label': 'Owner' }"
      :data-source="dataSource"
      v-model:value="currentValue"
      display-expr="FullName"
      value-expr="ID"
      content-template="contentTemplate"
    >
      <template #contentTemplate="{}">
        <DxDataGrid
          :data-source="dataSource"
          :remote-operations="true"
          :height="250"
          :selected-row-keys="[currentValue]"
          :hover-state-enabled="true"
          :on-selection-changed="onSelectionChanged"
          :focused-row-enabled="true"
          :focused-row-key="currentValue"
        >
          <DxColumn data-field="FullName"/>
          <DxColumn data-field="Title"/>
          <DxColumn data-field="Department"/>
          <DxPaging
            :enabled="true"
            :page-size="10"
          />
          <DxScrolling mode="virtual"/>
          <DxSelection mode="single"/>
        </DxDataGrid>
      </template>
    </DxDropDownBox>
  </template>
  <script>
  
  import {
    DxDataGrid,
    DxPaging,
    DxSelection,
    DxScrolling,
    DxColumn,
    //DxDataGridTypes,
  } from 'devextreme-vue/data-grid';
  import DxDropDownBox
  //, { DxDropDownBoxTypes } 
  from 'devextreme-vue/drop-down-box';

  
  //const currentValue = ref(props.value);
  
    export default {
    name: "OwnerEditorTemplate",
    props:{
        value: {
            type: Number
        },
        onValueChanged: {
            type: Function
        },
        dataSource: {
            type: Array
        }
    },
    computed: {},
    data(){
        return {
            dropDownOptions: { width: 500 },
            currentValue: ''
        };
    },
    methods: {
        onSelectionChanged(e) { //: DxDataGridTypes.SelectionChangedEvent
            //currentValue.value = e.selectedRowKeys[0];
            this.onValueChanged(currentValue.value);
  
            if (e.selectedRowKeys.length > 0) {
                this.$refs['dropDownBoxRef'].instance?.close();
            }
        },
    }
  };

  </script>
  