<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 text-left">
        <label class="control-label">{{ $t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")'
               :class="{ 'field-error' : $v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="row">
          <div class="col form-group">
            <label class="control-label">{{$t("stock_management.partners.type")}}</label>
            <b-form-select
              v-model="data.partner_type"
              class="form-control"
              :class="{ 'field-error' : $v.data.partner_type.$error }"
              :options="partner_type_list"></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 text-left">
        <label class="control-label">{{ $t('stock_management.partners.id_number') }}</label>
        <input id="id_number" type="text" v-model="data.id_number"
               placeholder='ROXXX'
               v-on:keydown="onlyCompanyIdentification_number"
               :class="{ 'field-error' : $v.data.id_number.$error }"
               class="form-control upper"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 text-left">
        <label class="control-label">{{ $t('stock_management.partners.nr_reg') }}</label>
        <input v-model="data.nr_reg"
               class="form-control upper"
               v-on:keydown="onlyCompanyNrRegNumber"
               placeholder="JXX/XXX/YYYY"/>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 mt-4">
        <b-form-checkbox switch size="lg"
                         v-model="is_active"
                         @change="onIsActiveChanged">
          {{ is_active ? $t('common.active') : $t('common.inactive') }}
        </b-form-checkbox>
      </div>
    </div>
  </card>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import {buildDropdownOptions, only_company_identification_number, only_company_nr_reg_number} from "~/helpers/common";
import {mapActions, mapGetters} from "vuex";
import * as actions from "~/helpers/generalActions";
import {canDelete, canEdit} from "~/helpers/Permissions";

export default {
  name: 'PartnerDetailsTemplate',
  props: {
    data: {
      type: Object,
    },
    permission: {
      type: Object,
    },
    title: {
      type: String,
      default(val) {
        return this.$t('stock_management.partners.create_new');
      }
    }
  },
  data() {
      return {
        is_global: true,
        is_active: true,
        partner_types: this.partnerTypes ? this.partnerTypes : [],
      }
  },
  computed: {
    ...mapGetters({
        partnerTypes: 'smPartners/smPartnerTypes',
      }),
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    partner_type_list() {
      let vm = this;
      return buildDropdownOptions(Object.keys(this.partnerTypes).map((key) => {
        return vm.partnerTypes[key];
      }), {
        value: 'uuid',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    },
    validator() {
      return this.$v;
    }
  },
  methods: {
    ...mapActions({
        getPartnerTypes: 'smPartners/getSmPartnerTypes',
    }),
    onlyCompanyIdentification_number(event) {
      return only_company_identification_number(event);
    },
    onlyCompanyNrRegNumber(event) {
      return only_company_nr_reg_number(event);
    },
    onIsActiveChanged(val) {
      this.data.is_active = val ? 1 : 0;
    },
    validate() {
      this.$v.data.$touch();

      return !this.$v.data.$invalid;
    },
    getData() {
      return this.data;
    },
  },
  mounted() {
    this.getPartnerTypes();
  },
  watch: {
    partnerTypes: {
      handler: function(newVal) {
        this.partner_types = Object.keys(newVal).map((key) => {
          return newVal[key];
        });
      },
      deep: true,
    },
    data: {
      handler: function(newVal) {
        this.$emit('onChange', newVal);
      }
    }
  },
  validations() {
    return {
      data: {
        name: {
          required,
        },
        partner_type: {
          required,
        },
        id_number: {
          required,
        },
      }
    }
  }
}
</script>
