import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from '../../api/../mutation-types'

const actions = {
    //MeasureUnits
    getMeasureUnits({commit}, payload) {
          return api.fetch(endpoints.MEASURE_UNITS, payload, commit, (response) => {
            commit(types.SET_MEASURE_UNITS, response.data.data);
              commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
          });
    },
    searchMeasureUnits({commit}, payload) {
      return api.fetch(endpoints.MEASURE_UNITS, payload, commit, (response) => {
        commit(types.SET_MEASURE_UNITS, response.data.data);
      });
    },
    getMeasureUnit({commit}, payload) {
      return api.fetch(endpoints.MEASURE_UNITS+'/'+payload.uuid, payload, commit,  (response) => {
        commit(types.SET_MEASURE_UNIT, response.data);
      });
    },
    createMeasureUnit({commit}, payload) {
      return api.post(endpoints.MEASURE_UNITS, payload,  commit,(response) => {
        commit(types.SET_MEASURE_UNIT, response.data);
      });
    },
    updateMeasureUnit({commit}, payload) {
      return api.put(endpoints.MEASURE_UNITS+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_MEASURE_UNIT, response.data);
      })
    },
    deleteMeasureUnit({commit}, payload) {
      return api.remove(endpoints.MEASURE_UNITS+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_MEASURE_UNIT, response.data);
      })
    },
}

export default actions;
