import axios from '@/plugins/axios'
import * as types from "../mutation-types";
import * as endpoints from '../api/endpoints'
import {DELETE_DEPARTMENT_ITEM} from "../mutation-types";

const actions = {
    getDepartments({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(endpoints.DEPARTMENTS, {
                params: payload,
            }).then((response) => {
                commit(types.SET_DEPARTMENTS, response.data.data);
                resolve(response.data.data);
            }).catch((e) => {
                commit("common/" + types.SET_ERROR_MESSAGE, e, { root: true });
                reject(e);
            });
        })
    },
    listDepartments({commit}, payload) {
        console.log('list departments?');
      return new Promise((resolve, reject) => {
        axios.get(endpoints.DEPARTMENTS, {
          params: payload,
        }).then((response) => {
          commit(types.SET_DEPARTMENT_LIST, response.data.data);
          resolve(response.data.data);
        }).catch((e) => {
          commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
          reject(e);
        });
      })
    },
    getDepartment({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(endpoints.DEPARTMENTS + '/' + payload.uuid, {
                params: {},
            }).then((response) => {
                commit(types.SET_DEPARTMENT, response.data.data);
                resolve(response.data.data);
            }).catch((e) => {
                commit("common/" + types.SET_ERROR_MESSAGE, e, { root: true });
                reject(e);
            });
        })
    },
    getParentDepartments({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(endpoints.PARENT_DEPARTMENTS, {
                params: {

                },
            }).then((response) => {
                commit(types.SET_PARENT_DEPARTMENTS, response.data.data);
                resolve(response.data.data);
            }).catch((e) => {
                commit("common/" + types.SET_ERROR_MESSAGE, e, { root: true });
                reject(e);
            });
        })
    },
    createDepartment({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(endpoints.DEPARTMENTS, payload).then((response) => {
                commit(types.SET_DEPARTMENT, response.data.data);
                resolve(response.data);
            }).catch((e) => {
                reject(e.response.data);
            });
        })
    },
    updateDepartment({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.put(endpoints.DEPARTMENTS + '/' + payload.uuid, payload).then((response) => {
                commit(types.SET_DEPARTMENT, response.data.data);
                resolve(response.data);
            }).catch((e) => {
                reject(e.response.data);
            });
        })
    },
    deleteDepartment({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(endpoints.DEPARTMENTS + '/' + payload.uuid).then((response) => {
                commit(types.SET_DEPARTMENT, response.data.data);
                commit(types.DELETE_DEPARTMENT_ITEM, response.data.data);
                resolve(response.data);
            }).catch((e) => {
                reject(e);
            });
        })
    },
}

export default actions;
