<template>
  <div id="sc-partner-list-filters" class="filter-actions col-lg-12 filters p-3">
    <div class="row">
      <div class="col-lg form-group">
        <label class="control-label">{{$t("name")}}</label>
        <input v-model="filters.name" class="form-control" :placeholder="$t('name')"/>
      </div>
      <div class="col-sm-6 col-lg form-group">
        <label class="control-label">{{$t("stock_management.partners.type")}}</label>
        <b-form-select
          v-model="filters.partner_type"
          class="form-control"
          :options="partner_type_list"></b-form-select>
      </div>
      <div class="col-lg-2 form-group">
        <label class="control-label">{{$t("stock_management.partners.id_number")}}</label>
        <input v-model="filters.id_number" class="form-control"
               v-on:keydown="onlyCompanyIdentification_number"
               :placeholder="$t('stock_management.partners.id_number')"/>
      </div>
      <div class="col-sm-6 col-lg form-group">
        <label class="control-label">{{$t("common.active")}}</label>
        <b-form-select
          v-model="filters.is_active"
          class="form-control"
          :options="active_list"></b-form-select>
      </div>
      <div class="form-group col-sm-4 col-lg">
        <label class="control-label">{{$t("common.date_from")}}</label>
        <date-picker2
          v-model="date_from"
          :value-type="date_from_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          @change="onDateFromChanged"
          ref="date_from"
        ></date-picker2>
      </div>
      <div class="form-group col-sm-4 col-lg">
        <label class="control-label">{{$t("common.date_to")}}</label>
        <date-picker2
          v-model="date_to"
          :value-type="date_to_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          @change="onDateToChanged"
          ref="date_from"
        ></date-picker2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 mt-3 pt-2">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-3 pt-2">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'
import {buildDropdownOptions, only_company_identification_number} from "@/helpers/common";
import DatePicker2 from 'vue2-datepicker';

export default {
  name: 'SMPartnerFilters',
  props: ["limit"],
  data() {
    return {
      filters:  this.gridFilters ? this.gridFilters : {},
      active_list: [
        { value: '', text: this.$t('common.all') },
        { value: 1, text: this.$t('common.active') },
        { value: 0, text: this.$t('common.inactive') },
      ],
      date_from: null,
      date_to: null,
      date_from_input: null,
      date_to_input: null,
    }
  },
  components: {
    DatePicker2
  },
  computed: {
    ...mapGetters({
      gridFilters: 'smPartners/smPartnerFilters',
      partnerTypes: 'smPartners/smPartnerTypes'
    }),
    partner_type_list() {
      return buildDropdownOptions(Object.assign([], this.partnerTypes), {
        value: 'uuid',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    },
  },
  methods: {
    ...mapActions({
      getData: 'smPartners/getSmPartners',
      getPartnerTypes: 'smPartners/getSmPartnerTypes'
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$router.push({
          path: '/stock-management/partners',
          params: {
            page: 1,
          }
        }
      );
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.getData(this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        partner_type: null,
        id_number: '',
        date_from: null,
        date_to: null,
        is_active: '',
        page: 1,
        limit: this.limit,
      };

      this.$store.commit("smPartners/"+type.SET_SM_PARTNER_FILTERS, this.filters);
      this.applyFilters();
    },
    onlyCompanyIdentification_number(event) {
      return only_company_identification_number(event);
    },
    onDateFromChanged(date) {
      this.filters.date_from = date;
    },
    onDateToChanged(date) {
      this.filters.date_to = date;
    },
  },
  mounted(){
    this.getPartnerTypes();
    this.resetFilters();
  },
  watch: {
    gridFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    }
  }
}
</script>
