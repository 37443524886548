<template>
  <div class="cell_layer_maps">
    <DxPopup
      id="dx-dialog"
      width="100vw"
      height="calc(100vh - 50px)"
      position="bottom"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      :resize-enabled="false"
      :darg-enabled="false"
      @hidden="$emit('hidden')"
      :wrapper-attr="popupAttributes"
    >
      <template #title>
         <div class="map-filter-title">
            <div class="map-filter-close">
              <img
                src="@/assets/images/icons/dialog-close-white.svg"
                @click="buttonCloseEvent"
              />
            </div>
          </div>
      </template>
      <template #content>
        <div class="parcel-info-container">
        <DxTabPanel
            :data-source="filterTabs"
            :show-nav-buttons="true"
            class="map-filter-tabs"
            :elementAttr="tabPanelAttributes"
            @selection-changed="tabSelectionChanged"
        >
          <template #item="{ data: step }">
            <div>
              <div class="add-new-work">
                <b-button v-show="step == 'LUCRARI'"
                    class="parcel_add_article_icon"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="addNewArticleForParcel"
                    ><img src="@/assets/images/icons/plus.svg"
                    /></b-button>
                    <b-button v-show="step == 'SMAVERTIZARE'"
                    class="parcel_add_article_icon"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="addNewScoutingFromParcel"
                    ><img src="@/assets/images/icons/plus.svg"
                    /></b-button>
              </div>
              <div v-show="step == 'LUCRARI'">
                <div class="parcel-maps-title">
                  <div v-if="false" class="left_side">
                    <span  @click="closeAndSwitchToList">Înapoi la listă</span>
                  </div>
                  <div v-if="false" class="right_side">
                    <span  @click="buttonCloseEvent">Înapoi la hartă</span>
                  </div>
                   <div class="works_label">Ai selectat parcela <span>{{parcel_name}} ({{parcel_surface}} ha)</span><span v-if="layer.culture"> - {{ layer.culture.name }}</span></div>
                </div>
                <DxScrollView class="parcel-maps-content" :use-native="true" >
                  <works
                  ref="worksModule"
                  usage_mode="LIGHT"
                  :filters="localFilters"
                  :parcel_uuid="parcel_uuid"
                  :parcel_name="parcel_name"
                  :season="currentSeason"
                  :start_mode="works_start_mode"
                  @setParentShowAddArticle="setParentShowAddArticle"
                  @showToastMessage="showToastMessage"></works>
                </DxScrollView>
                <div class="parcel_info_continue_add_article_dialog" v-show="works_start_mode == true && addArticleShowing == false">
                  <b-button
                      class="continue_button"
                      variant="outline-secondary"
                      size="sm"
                      pill
                      @click="showAddArticleDialogFunction"
                      >Continuă</b-button
                    >
                  <b-button
                    class="continue_button second_action_button"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="cancelAddProduct"
                    >Anulează</b-button
                  >

              </div>
              </div>
              <div v-show="step == 'CONSUM'">
                   <div class="parcel-maps-title">
                  <div v-if="false" class="left_side">
                    <span  @click="closeAndSwitchToList">Înapoi la listă</span>
                  </div>
                  <div v-if="false" class="right_side">
                    <span  @click="buttonCloseEvent">Înapoi la hartă</span>
                  </div>
                   <div v-if="false" class="works_label">Ai selectat parcela <span>{{parcel_name}} ({{parcel_surface}} ha)</span></div>
                </div>
                 <DxScrollView class="parcel-maps-content-cons" :use-native="true" >
                    <parcel-consums v-if="false" :parcel_uuid="parcel_uuid" :parcel_name="parcel_name" :parcel_surface="parcel_surface"></parcel-consums>
                    <consolidate-consum :parcel_uuid="parcel_uuid" :parcel_name="parcel_name" :parcel_surface="parcel_surface"></consolidate-consum>
                 </DxScrollView>
              </div>
              <div v-show="step == 'SMAVERTIZARE'">
                <div class="parcel-maps-title">
                   <div class="works_label">Ai selectat parcela <span>{{parcel_name}} ({{parcel_surface}} ha)</span><span v-if="layer.culture"> - {{ layer.culture.name }}</span></div>
                </div>
                <DxScrollView class="parcel-maps-content-scouting" :elementAttr="scrollListAttributesScouting"  :use-native="true" >
                    <ScoutingList :parcel_uuid="parcel_uuid" :season_on_parcel_uuid="season_on_parcel_uuid" ref="scoutingListControl"></ScoutingList>
                    <CreateScouting :show-popup="addScoutingVisibility" @hidden="hideAddNewScoutingFromParcel" @refresh="refreshScoutingListAfterCreate" :parcel_uuid="parcel_uuid" :season_on_parcel_uuid="season_on_parcel_uuid"></CreateScouting>
                </DxScrollView>
              </div>
            </div>
          </template>
        </DxTabPanel>
         <add-action-selector
            :showPopup="addActionSelectorVisibility"
            @hidden="hideAddActionSelector"
            @startAddProduct="addArticleFunction"
            @startAddWork="showManualWorkDialog"
          ></add-action-selector>
         <manual-work-dialog
          :templateData="manualWorkTemplateData"
          :showPopup="maualWorkVisibility"
          :selectedLayer="selectedLayer"
          @hidden="hideManualWork"
          @hideAndRefresh="hideManualWorkAndRefresh"
          @showToastMessage="showToastMessage">
        </manual-work-dialog>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import Works from "../partials/works.vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxTabPanel from 'devextreme-vue/tab-panel';
import ParcelConsums from '../../../stock-mobile/partials/parcelConsums.vue';
import ScoutingList from '../../../scouting-mobile/partials/scouting-list.vue';
import CreateScouting from "../../../scouting-mobile/dialogs/create-scouting.vue"
import ConsolidateConsum from '../../../../components/Map/consolidateConsum.vue';
import ManualWorkDialog from "./manualWorkDialog.vue";
import AddActionSelector from "./addActionSelector.vue";

export default {
  name: "ParcelInfoDialog",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    templateData: {
      type: Object,
      default: () => {},
    },
    selectedLayer: {
      type: Object,
      default: () => {},
    },
    parcel_uuid: String,
    parcel_name: String,
    parcel_surface: String,
    season_on_parcel_uuid: String,
    layer: Object,
    filters: Object,
  },
  data() {
    return {
      addActionSelectorVisibility: false,
      addScoutingVisibility: false,
      maualWorkVisibility: false,
      addArticleShowing: false,
      manualWorkTemplateData: {},
      localFilters: this.filters ? this.filters : {},
      currentSeason:'',
      popupAttributes: {
          class: 'parcel-info-attribute-wrapper'
      },
      tabPanelAttributes: {
          class: 'parcel-info-tabs-attribute-wrapper'
      },
      filterTabs: ["LUCRARI", "CONSUM", "SMAVERTIZARE"],
      works_start_mode: false,
      scrollListAttributesScouting:{
        class: 'scouting_list_scroller_on_parcel'
      }
    };
  },
  components: {
    DxPopup,
    DxPosition,
    Works,
    DxScrollView,
    DxTabPanel,
    ParcelConsums,
    ManualWorkDialog,
    AddActionSelector,
    ConsolidateConsum,
    ScoutingList,
    CreateScouting
  },
  computed: {
    ...mapGetters({
      currentDepartment: "auth/currentDepartment",
      seasons: 'seasons/seasons',
      departments: "company/departments",
    }),
  },
  watch: {
    showPopup(newVal) {
      console.log('SELECTED LAYER -> ', this.selectedLayer);
      if (newVal == true && this.$refs.worksModule != undefined) {
        this.setCurrentSeason(this.seasons);
        this.loadWorks();
      }
    },
    filters: {
      handler: function(newVal) {
        this.localFilters = newVal;
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    ...mapActions({
          getConsums: 'smTransactions/getConsumTransactions',
      }),
      tabSelectionChanged(option){
        if(option.addedItems && option.addedItems.length > 0 && option.addedItems[0] == 'SMAVERTIZARE'){
          this.$refs.scoutingListControl.fillFirstPage();
        }

      },
    loadWorks(){
      this.$refs.worksModule.applyFilters({
          departments: this.departments.map(d => d.uuid),
          date_from: "",
          date_to: "",
          works: [],
          machines: [],
          parcels: [this.parcel_uuid],
          products: [],
          src: this.localFilters.src,
          seasons: this.localFilters.seasons,
        });
    },
    buttonCloseEvent() {
      this.$emit("hidden");
    },
    closeAndSwitchToList() {
      this.$emit("closeAndSwitchToList");
    },
    setCurrentSeason(seasons){

      if(seasons.length === 0) return;

      this.currentSeason = seasons[0].uuid;
      let tmp_currentSeason = seasons[0];
      seasons.forEach((obj) => {
        if(new Date(obj.created_at) > new Date(tmp_currentSeason.created_at)){
          this.$set(this, 'currentSeason', obj.uuid);
        }
      });

    },
    addNewArticleForParcel(){
      this.addActionSelectorVisibility = true;
    },
    hideAddActionSelector(){
      this.addActionSelectorVisibility = false;
    },
    addNewScoutingFromParcel(){
      this.addScoutingVisibility = true;
    },
    hideAddNewScoutingFromParcel(){
      this.addScoutingVisibility = false;
    },
    refreshScoutingListAfterCreate(){
      this.$refs.scoutingListControl.refreshAfterCreate();
    },
    addArticleFunction(){
      this.works_start_mode = true;
    },
    showManualWorkDialog(){
      this.maualWorkVisibility = true;
    },
    hideManualWork(){
      this.maualWorkVisibility = false;
    },
    hideManualWorkAndRefresh(addProduct, response_data){
      this.hideManualWork();
      //this.loadWorks();
      if(addProduct){
        this.$refs.worksModule.fillWorkAndAddProductAfterInsert(response_data);
      }
      this.$refs.worksModule.fillWorkList(null);
    },
    showAddArticleDialogFunction(){
      this.$refs.worksModule.showAddArticleDialogFunction();
    },
    cancelAddProduct(){
        this.works_start_mode = false;
        this.$refs.worksModule.deselectAll();
    },
    setParentShowAddArticle(newVal){
      this.addArticleShowing = newVal;
      if(newVal == false){
        this.cancelAddProduct();
      }
    },
    showToastMessage(){}
  },
  mounted: function () {

  },
};
</script>


<style lang="scss">
@import "@/assets/scss/digitalAgenda/parcelInfoDialog.scss";
</style>
